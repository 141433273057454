import {
  Box,
  Paper,
  Button,
  TextField,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import logo from "../../logo.png";
import DividerWithText from "../common/divider/DividerWithText";
import GoogleSignInButton from "../common/buttons/GoogleSignInButton";
import { useDispatch, useSelector } from "react-redux";
import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  signinWithApple,
} from "../../store/actions/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import AppleSignInButton from "../common/buttons/AppleSignInButton";

const justifyCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const SignInButton = styled(Button)({
  textTransform: "none",
  height: "40px",
  width: "100%",
});

function SignIn() {
  const theme = useTheme();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.auth.authError);
  const { t } = useTranslation();

  const signInError = (error) => {
    if (error.code === "auth/invalid-email")
      return toast.error(t("error.auth/invalid-email"));
    else if (error.code === "auth/wrong-password")
      return toast.error(t("error.auth/wrong-password"));
    else if (error.code === "auth/user-not-found")
      return toast.error(t("error.auth/user-not-found"));
  };

  function handlePasswordFieldChange(e) {
    setPassword(e.target.value);
  }

  function handleEmailFieldChange(e) {
    setEmail(e.target.value);
  }

  useEffect(() => {
    console.log(auth);
    if (auth.uid) {
      history.replace("/dashboard");
    } else if (authError !== null) {
      signInError(authError);
      console.log(authError);
    }
  }, [auth.uid, authError, history]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: "650px",
          minWidth: "200px",
          margin: "10px auto",
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Paper
          sx={{
            margin: "10px",
            padding: "20px",
          }}
          elevation={10}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={justifyCenter}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "230px",
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <GoogleSignInButton
                title={t("button.signin_google")}
                onClick={() => {
                  dispatch(signInWithGoogle());
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <AppleSignInButton
                title={t("button.signin_apple")}
                onClick={() => {
                  dispatch(signinWithApple());
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DividerWithText>
                {t("text.has_password_signin_with_email")}
              </DividerWithText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("text.email")}
                placeholder="yourname@example.com"
                onChange={handleEmailFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("text.password")}
                type="password"
                placeholder="********"
                onChange={handlePasswordFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <SignInButton
                color="primary"
                size="small"
                variant="contained"
                disableElevation={true}
                onClick={() =>
                  dispatch(
                    signInWithEmailAndPassword({
                      email: email,
                      password: password,
                    })
                  )
                }
              >
                {t("button.signin_email")}
              </SignInButton>
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <Link to="/send_password_reset_email">
                <Typography variant="caption" color="primary">
                  {t("text.forgot_password")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Toaster />
        </Paper>
      </Box>
    </div>
  );
}

export default SignIn;
