import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

const BASE_URL = "https://beta.api.daheimladen.com/v1/";
const api = axios.create({
  baseURL: BASE_URL,
});

export const getCurrent = async ({ queryKey }) => {
  const [_, chargingStation] = queryKey;
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((idToken) => {
    const data = {
      key: "ChargeRate",
    };
    return api.post(`/cs/${chargingStation}/get_config`, data, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  });
};

export const changeCurrent = ({ chargingStationID, chargingCurrent }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((idToken) => {
    const data = {
      charging_station_id: chargingStationID,
      key: "ChargeRate",
      value: chargingCurrent,
    };
    return api.post(`/cs/${chargingStationID}/change_config`, data, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  });
};

export const getUserAccountSettings = () => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .get(`/user/get_account_settings`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const updateUserAccountSettings = ({
  lang,
  streetName,
  buildingNumber,
  postalCode,
  city,
  country,
}) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((idToken) => {
    return api.post(
      `/user/set_account_settings`,
      {
        lang: lang,
        address: {
          street_name: streetName,
          building_number: buildingNumber,
          postal_code: postalCode,
          city: city,
          country: country,
        },
      },
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
  });
};

export const getMeterPowerfoxRQ = () => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .get(`/pv/get_powerfox`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const insertOrUpdateMeterPowerfox = ({
  uid,
  chargingStationID,
  mode,
  minExcessPower,
  pvSupportedMaxHomeConsumption,
  batteryCheckDurationMins,
  username,
  password,
}) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((idToken) => {
    return api.post(
      `/pv/insert_or_update_powerfox`,
      {
        uid: uid,
        charging_station_id: chargingStationID,
        pv_mode: mode,
        min_excess_power: minExcessPower,
        pv_supported_max_home_consumption: pvSupportedMaxHomeConsumption,
        battery_check_duration_mins: batteryCheckDurationMins,
        username: username,
        password: password,
      },
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
  });
};

export const updatePVMode = ({ uid, mode }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .post(
        `/pv/${uid}/update_pv_mode`,
        { pv_mode: mode },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        return res.data;
      });
  });
};

export const deletePowerfox = () => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .delete(`/pv/delete_powerfox`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const getChargingStation = (chargingStationID) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .get(`/cs/${chargingStationID}/get_chargingstation`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const getPowerRates = ({ queryKey }) => {
  const [_, chargingStation] = queryKey;
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api.get(`/cs/${chargingStation}/get_power_rates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  });
};

export const deletePowerRate = ({ uid }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .delete(`/cs/power_rate/${uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const insertOrUpdatePowerRates = ({ req }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .post(`/cs/insert_or_update_power_rate`, req, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return {
          statusCode: res.status,
        };
      });
  });
};

export const getChargingStationSettings = ({ queryKey }) => {
  const [_, chargingStation] = queryKey;
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api.get(`/cs/${chargingStation}/get_settings`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  });
};

export const setChargingStationSettings = ({ chargingStation, req }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api.post(`/cs/${chargingStation}/set_settings`, req, {
      headers: { Authorization: `Bearer ${token}` },
    });
  });
};

export const getCards = ({ queryKey }) => {
  const [_, chargingStation] = queryKey;
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api.get(`/cs/${chargingStation}/get_cards`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  });
};

export const updatePhases = ({ chargingStationID, phases }) => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((token) => {
    return api
      .post(
        `/cs/${chargingStationID}/update_phases`,
        { phases: phases },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res.data);
  });
};

export const remoteStart = ({ chargingStationID }) => {
  const user = firebase.auth().currentUser;
  const data = {
    connector_id: 1,
    idtag: user.uid.substring(0, 10),
  };
  return user.getIdToken(true).then((token) => {
    return api
      .post(`/cs/${chargingStationID}/remotestart`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const remoteStop = ({ chargingStationID, transactionID }) => {
  const user = firebase.auth().currentUser;
  const data = {
    transaction_id: transactionID,
  };
  return user.getIdToken(true).then((idToken) => {
    return api
      .post(`/cs/${chargingStationID}/remotestop`, data, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const getChargingStationFromUser = () => {
  const user = firebase.auth().currentUser;
  return user.getIdToken(true).then((idToken) => {
    return api
      .get(`/cs/get_cs`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((res) => {
        return res.data;
      });
  });
};

export const addChargingStations = ({ chargingStations }) => {
  const user = firebase.auth().currentUser;
  var filteredStations = chargingStations.filter((cs) => cs.isLocal);

  var chargingStationsArrObj = filteredStations.map((cs) => {
    return { charging_station_id: cs.charging_station_id, alias: cs.alias };
  });

  return user.getIdToken(true).then((idToken) => {
    const addCSReq = {
      uid: user.uid,
      charging_stations: [...chargingStationsArrObj],
    };

    return api.post("/cs/add_cs", addCSReq, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  });
};
