import React from "react";
import ContentLoader from "react-content-loader";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";

const PREFIX = "Skeleton";
const classesx = {
  table_Skeleton: `${PREFIX}-table`,
  Loading_Card: `${PREFIX}-Loading_Card`,
  LoadingCharging: `${PREFIX}-LoadingCharging`,
  ChargingStartedSkeleton1: `${PREFIX}-ChargingStartedSkeleton1`,
  ChargingStartedSkeleton2: `${PREFIX}-ChargingStartedSkeleton2`,
  ChargingStartedSkeleton3: `${PREFIX}-ChargingStartedSkeleton3`,
  ChartsSkeleton: `${PREFIX}-ChartsSkeleton`,
  Inputstack: `${PREFIX}-Inputstack`,
  selectstack: `${PREFIX}-selectstack`,
  groupbtnstack: `${PREFIX}-groupbtnstack`,
  SliderSkeleton: `${PREFIX}-SliderSkeleton`,
};
const SkeletonRoot = styled(Skeleton)(({ theme }) => ({
  [`&.${classesx.SliderSkeleton}`]: {
    width: "100%",
    height: 8,
    borderRadius: "15px",
    marginTop: "13px",
  },
  [`&.${classesx.groupbtnstack}`]: {
    width: "100%",
    maxWidth: "215px",
    height: 53,
    borderRadius: "10px",
    marginBottom: "15px",
    [theme.breakpoints.down("md")]: {
      height: 49,
    },
    [theme.breakpoints.down("sm")]: {
      height: 41,
    },
  },
  [`&.${classesx.selectstack}`]: {
    width: "100%",
    maxWidth: "265px",
    height: 70,
    borderRadius: "100px",
    marginBottom: "30px",
    [theme.breakpoints.down("xl")]: {
      height: 55,
    },
    [theme.breakpoints.down("lg")]: {
      height: 51,
    },
    [theme.breakpoints.down("xs")]: {
      height: 41,
    },
  },
  [`&.${classesx.Inputstack}`]: {
    width: "100%",
    height: 40,
    borderRadius: "40px",
    [theme.breakpoints.down("sm")]: {
      height: 32.5,
    },
  },
  [`&.${classesx.table_Skeleton}`]: {
    height: 80,
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      height: 73,
    },
    [theme.breakpoints.down("lg")]: {
      height: 69,
    },
  },
  [`&.${classesx.Loading_Card}`]: {
    height: 345,
    width: "100%",
    borderRadius: "25px",
    margin: "0 0 20px 0",
    [theme.breakpoints.down("xs")]: {
      height: 325,
      margin: "0 0 15px 0",
      borderRadius: "15px",
    },
  },
  [`&.${classesx.LoadingCharging}`]: {
    height: 140,
    width: "100%",
    margin: "20px 0 0 0",
    borderRadius: "0 0 15px 15px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: "0 0 10px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      height: 125,
      margin: "10px 0 0 0",
    },
    [theme.breakpoints.down("xs")]: {
      height: 115,
      margin: "10px 0 0 0",
    },
  },
  [`&.${classesx.ChargingStartedSkeleton1}`]: {
    height: 30,
    width: "100%",
    borderRadius: "15px 15px 0 0",
    [theme.breakpoints.down("xl")]: {
      borderRadius: "10px 10px 0 0",
    },
  },
  [`&.${classesx.ChargingStartedSkeleton2}`]: {
    height: 48,
    width: "100%",
    margin: "20px 0 40px 0",
    [theme.breakpoints.down("lg")]: {
      margin: "20px 0",
      height: 40,
    },
    [theme.breakpoints.down("sm")]: {
      height: 33,
    },
    [theme.breakpoints.down("xs")]: {
      height: 28,
    },
  },
  [`&.${classesx.ChargingStartedSkeleton3}`]: {
    height: 55,
    minWidth: "100%",
    borderRadius: "0 0 15px 15px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: "0 0 10px 10px",
    },
    [theme.breakpoints.down("lg")]: {
      height: 48,
    },
    [theme.breakpoints.down("sm")]: {
      height: 113,
    },
    [theme.breakpoints.down("xs")]: {
      height: 96,
    },
  },
  [`&.${classesx.ChartsSkeleton}`]: {
    height: 430,
    minWidth: "100%",
    borderRadius: "30px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: "25px",
    },
    [theme.breakpoints.down("lg")]: {
      height: 400,
      borderRadius: "15px",
    },
    [theme.breakpoints.down("md")]: {
      height: 380,
      borderRadius: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      height: 310,
    },
    [theme.breakpoints.down("xs")]: {
      height: 250,
      borderRadius: "5px",
    },
  },
}));

const LoadingTable = () => {
  const theme = useTheme();
  return (
    <>
      <Stack
        spacing={1}
        sx={{
          overflow: "hidden",
          borderRadius: "20px",
          [theme.breakpoints.down("xl")]: {
            borderRadius: "16px",
          },
          [theme.breakpoints.down("md")]: {
            borderRadius: "10px",
          },
        }}
      >
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
        <SkeletonRoot
          className={classesx.table_Skeleton}
          animation="wave"
          variant="rounded"
        />
      </Stack>
    </>
  );
};
const LoadingCard = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.Loading_Card}
        animation="wave"
        variant="rounded"
      />
      <SkeletonRoot
        className={classesx.Loading_Card}
        animation="wave"
        variant="rounded"
      />
      <SkeletonRoot
        className={classesx.Loading_Card}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const LoadingCharging = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.LoadingCharging}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const ChargingStartedSkeleton1 = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.ChargingStartedSkeleton1}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const ChargingStartedSkeleton2 = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.ChargingStartedSkeleton2}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const ChargingStartedSkeleton3 = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.ChargingStartedSkeleton3}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const ChartsSkeleton = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.ChartsSkeleton}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const Inputstack = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.Inputstack}
        animation="wave"
        variant="rounded"
      />
      <SkeletonRoot
        className={classesx.Inputstack}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const Selectstack = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.selectstack}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const SelectstackReport = () => {
  return (
    <>
      <SkeletonRoot
        sx={{ maxWidth: "100%!important", marginBottom: "unset!important" }}
        className={classesx.selectstack}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const Groupbtnstack = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.groupbtnstack}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

const SliderSkeleton = () => {
  return (
    <>
      <SkeletonRoot
        className={classesx.SliderSkeleton}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};
const LoadingCardLoad = () => {
  return (
    <>
      <SkeletonRoot
        sx={{
          minWidth: "100%",
          height: "260px!important",
          margin: "0px!important",
        }}
        className={classesx.Loading_Card}
        animation="wave"
        variant="rounded"
      />
    </>
  );
};

function Loading() {
  return (
    <ContentLoader width={"100%"} height={50}>
      <rect x="0" y="0" rx="4" ry="4" width="80%" height="20"></rect>
      <rect x="0" y="30" rx="4" ry="4" width="60%" height="15"></rect>
    </ContentLoader>
  );
}

export {
  LoadingTable,
  LoadingCard,
  LoadingCharging,
  ChargingStartedSkeleton1,
  ChargingStartedSkeleton2,
  ChargingStartedSkeleton3,
  ChartsSkeleton,
  Inputstack,
  Selectstack,
  Groupbtnstack,
  SliderSkeleton,
  LoadingCardLoad,
  SelectstackReport,
};
export default Loading;
