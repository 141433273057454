import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  appendChargingStation,
  deleteChargingStation,
  getChargingStations,
} from "../../store/actions/chargingStation";
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { getFirebase } from "react-redux-firebase";
import { disableSave } from "../../store/actions/app";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import { classesx as classesxReport } from "./Report";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
} from "./Report";
import { DashboarClasses, GridRoot as GridRootDashboard } from "./Dashboard";
import {
  SettingClasses,
  SettingdDiv,
  RootH3,
  ButtonRoot,
  ULRoot,
  LIRoot,
  TextFieldRoot,
  IconButtonRoot,
} from "./Settings";
import Background from "../common/background/background";
import { useMutation } from "@tanstack/react-query";
import { addChargingStations } from "../../lib/api";
export const PREFIX = "accountsettings";
export const accountpageClasses = {
  GridRoot_container_accountsettings: `${PREFIX}-GridRoot_container`,
  pagetitleblock: `${PREFIX}-pagetitleblock`,
  pagetitleblockh2: `${PREFIX}-pagetitleblockh2`,
  chargingStationcard: `${PREFIX}-chargingStationcard`,
  chargingStationcard_cardbody: `${PREFIX}-chargingStationcard_cardbody`,
};
export const ChargingStationsDiv = styled("div")(({ theme }) => ({
  [`&.${accountpageClasses.pagetitleblock}`]: {
    marginBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  [`&.${accountpageClasses.chargingStationcard}`]: {
    [theme.breakpoints.down("lg")]: {
      [`& .test_card_left`]: {
        width: "calc(100% - 40px)!important",
      },
      [`& .test_card_right`]: {
        marginTop: "0px!important",
        width: "unset!important",
      },
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot:first-of-type`]: {
        marginRight: "15px!important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot:first-of-type`]: {
        marginRight: "0px!important",
        marginBottom: "15px",
      },
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot`]: {
        width: "100%",
      },
      [`& .test_card_left`]: {
        width: "100%!important",
      },
      [`& .test_card_right`]: {
        marginTop: "10px!important",
        width: "100%!important",
        justifyContent: "flex-end",
      },
    },
  },
}));
export const RootH2 = styled("h2")(({ theme }) => ({
  [`&.${accountpageClasses.pagetitleblockh2}`]: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.2,
    color: "#1C1C1C",
    textTransform: "capitalize",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
}));

function ChargingStationItem({ chargingStationID, alias, handleDelete }) {
  const theme = useTheme();
  return (
    <>
      <LIRoot className={SettingClasses.rifd_cards}>
        <div className="test_card_left">
          <TextFieldRoot
            disabled={true}
            placeholder="Charging Station ID"
            label="Charging Station ID"
            className={SettingClasses.TextFieldRoot}
            sx={{ marginRight: "30px" }}
            type="ChargingStation_ID"
            id="ChargingStation_ID"
            defaultValue={chargingStationID}
            variant="outlined"
            autoComplete="on"
          />
          <TextFieldRoot
            disabled={true}
            defaultValue={alias}
            variant="outlined"
            label="Alias"
            placeholder="Alias"
            className={SettingClasses.TextFieldRoot}
            type="Alias"
            id="Alias"
            autoComplete="on"
          />
        </div>
        <div className="test_card_right">
          <IconButtonRoot
            sx={{
              marginRight: "-8px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px",
              },
            }}
            className={SettingClasses.IconButtonRoot}
            required={true}
            onClick={handleDelete}
            size="large"
          >
            <DeleteOutlineIcon />
          </IconButtonRoot>
        </div>
      </LIRoot>
    </>
  );
}

function ChargingStation() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [chargingStationTextField, setChargingStationTextField] = useState("");
  const [aliasTextField, setAliasTextField] = useState("");
  const chargingStations = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChargingStations());
  }, []);

  function handleChargingStationTextField(e) {
    setChargingStationTextField(e.target.value);
  }

  function handleAliasTextField(e) {
    setAliasTextField(e.target.value);
  }

  return (
    <>
      <RootH3
        className={clsx(`${SettingClasses.setting_card_h3}`)}
        sx={{
          marginBottom: "20px!important",
          [theme.breakpoints.down("md")]: {
            marginBottom: "15px!important",
          },
          [theme.breakpoints.down("sm")]: {
            marginBottom: "10px!important",
          },
        }}
      >
        {chargingStations !== undefined &&
        chargingStations !== null &&
        chargingStations.length > 0 ? (
          <>{t("text.your_charging_stations")}</>
        ) : (
          <>{t("text.add_new_charging_station")}</>
        )}
      </RootH3>
      <ULRoot className={`${SettingClasses.rifd_cards_block}`}>
        {chargingStations !== undefined &&
        chargingStations !== null &&
        chargingStations.length > 0 ? (
          <>
            {chargingStations.map((cs) => (
              <ChargingStationItem
                key={cs.unqid}
                chargingStationID={cs.charging_station_id}
                alias={cs.alias}
                handleDelete={() => {
                  dispatch(deleteChargingStation(cs.unqid));
                }}
              />
            ))}
          </>
        ) : null}
        <LIRoot className={SettingClasses.rifd_cards}>
          <div className="test_card_left">
            <TextFieldRoot
              disabled={false}
              variant="outlined"
              value={chargingStationTextField}
              placeholder="Charging Station ID"
              label="Charging Station ID"
              onChange={handleChargingStationTextField}
              className={SettingClasses.TextFieldRoot}
              sx={{ marginRight: "30px" }}
              type="ChargingStation_ID"
              id="ChargingStation_ID"
              autoComplete="on"
            />

            <TextFieldRoot
              disabled={false}
              variant="outlined"
              value={aliasTextField}
              label="Alias"
              placeholder="Alias"
              onChange={handleAliasTextField}
              className={SettingClasses.TextFieldRoot}
              type="Alias"
              id="Alias"
              autoComplete="on"
            />
          </div>
          <div className="test_card_right">
            <IconButtonRoot
              sx={{
                marginRight: "-8px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: "0px",
                },
              }}
              className={SettingClasses.IconButtonRoot}
              required={true}
              disabled={
                chargingStationTextField === "" || aliasTextField === ""
              }
              onClick={() => {
                dispatch(
                  appendChargingStation({
                    charging_station_id: chargingStationTextField,
                    alias: aliasTextField,
                  })
                );
                setChargingStationTextField("");
                setAliasTextField("");
              }}
              size="large"
            >
              <AddCircleOutlineRoundedIcon />
            </IconButtonRoot>
          </div>
        </LIRoot>
      </ULRoot>
    </>
  );
}

function ChargingStationsPage() {
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const theme = useTheme();
  const saveDisabled = useSelector((state) => state.app.saveDisabled);
  const { t, i18n } = useTranslation();

  const chargingStations = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const addChargingStation = useMutation({
    mutationFn: addChargingStations,
    onSuccess: (data, variables, context) => {
      if (data !== null) {
        addChargingStationToast(data.status);
        dispatch(getChargingStations());
      }
    },
    onError: (err) => {
      if (err.response.status === 403) {
        addChargingStationToast(err.response.status);
      }
    },
  });

  const addChargingStationToast = (status) => {
    if (status === 200) return toast.success(t("text.charging_station_added"));
    else if (status === 403)
      return toast.error(t("text.charging_station_does_not_exist"));
  };

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      chargingStations !== null &&
      chargingStations.every((cs) => cs.isLocal === false)
    ) {
      dispatch(disableSave(true));
    } else {
      dispatch(disableSave(false));
    }
  }, [chargingStations]);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${accountpageClasses.GridRoot_container_accountsettings}`}
        >
          <ChargingStationsDiv className={accountpageClasses.pagetitleblock}>
            <RootH2 className={`${accountpageClasses.pagetitleblockh2}`}>
              {t("text.your_charging_stations")}
            </RootH2>
          </ChargingStationsDiv>
          <ChargingStationsDiv
            className={accountpageClasses.chargingStationcard}
          >
            <SettingdDiv
              className={`${SettingClasses.CardBody} ${accountpageClasses.chargingStationcard_cardbody}`}
              sx={{
                minHeight: "440px!important",
                [theme.breakpoints.down("lg")]: {
                  minHeight: "380px!important",
                },
                [theme.breakpoints.down("sm")]: {
                  minHeight: "430px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  minHeight: "400px!important",
                },
              }}
            >
              <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
                <ChargingStation />
              </SettingdDiv>
              <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
                <ButtonRoot
                  variant="contained"
                  color="primary"
                  className={`${SettingClasses.ButtonRoot}`}
                  disabled={saveDisabled}
                  onClick={() => {
                    addChargingStation.mutate({ chargingStations });
                  }}
                >
                  {t("button.save")}
                </ButtonRoot>
              </SettingdDiv>
            </SettingdDiv>
          </ChargingStationsDiv>
        </GridRootDashboard>
        <Toaster />
      </RootDivReport>
    </>
  );
}

export default ChargingStationsPage;
