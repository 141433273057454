import React from "react";
import { styled } from "@mui/material/styles";

const StyledSVG = styled("svg")(({ theme }) => ({
  position: "fixed",
  right: 0,
  bottom: 0,
  zIndex: 0,
  [theme.breakpoints.down("lg")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "530px",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "350px",
  },
  [theme.breakpoints.down("xs")]: {
    maxWidth: "300px",
  },
}));

export default function Background() {
  return (
    <StyledSVG
      opacity={0.2}
      width="800"
      viewBox="0 0 1321 687"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1041.86 234.72C1147.27 376.6 910.09 611.73 717.51 669.48C524.93 727.23 262.95 636.39 212.11 466.85C161.27 297.31 238.42 108.8 431 51.05C518.75 24.74 650.66 43.6499 746.16 109.08C870.08 193.97 962.06 127.32 1041.86 234.72Z"
        fill="#EEF5E5"
      />
      <path
        d="M883.82 418.2H789.12C783.02 418.2 778.07 413.25 778.07 407.15V347.15H894.88V407.15C894.88 413.26 889.93 418.2 883.82 418.2Z"
        fill="#5F8199"
      />
      <path
        d="M851.4 448.71H821.54C815.17 448.71 809.23 445.49 805.75 440.14L791.49 418.2H881.46L867.2 440.14C863.72 445.49 857.78 448.71 851.4 448.71Z"
        fill="#3B4F66"
      />
      <path
        d="M894.88 406.08H778.07C776.41 406.08 775.07 404.74 775.07 403.08C775.07 401.42 776.41 400.08 778.07 400.08H894.88C896.54 400.08 897.88 401.42 897.88 403.08C897.88 404.74 896.53 406.08 894.88 406.08Z"
        fill="white"
      />
      <path
        d="M894.88 389.39H778.07C776.41 389.39 775.07 388.05 775.07 386.39C775.07 384.73 776.41 383.39 778.07 383.39H894.88C896.54 383.39 897.88 384.73 897.88 386.39C897.88 388.05 896.53 389.39 894.88 389.39Z"
        fill="white"
      />
      <path
        d="M894.88 372.7H778.07C776.41 372.7 775.07 371.36 775.07 369.7C775.07 368.04 776.41 366.7 778.07 366.7H894.88C896.54 366.7 897.88 368.04 897.88 369.7C897.88 371.36 896.53 372.7 894.88 372.7Z"
        fill="white"
      />
      <path
        d="M840.75 106.37C850.95 124.04 826.91 132.71 799.35 148.63C771.79 164.54 751.14 181.67 740.94 164C730.74 146.33 743.03 112.44 770.6 96.52C798.16 80.61 830.55 88.7 840.75 106.37Z"
        fill="white"
      />
      <path
        d="M913.75 200.95C929.71 163.22 925.59 78.86 842.78 62.03C903.97 65.23 952.77 115.93 953.81 177.19C954.22 201.3 947.35 223.77 935.25 242.57C917.42 270.28 907.97 302.55 907.97 335.5C907.97 341.93 902.75 347.15 896.32 347.15H776.68C770.21 347.15 764.96 341.9 764.96 335.43C764.96 320.6 763.12 305.87 759.44 291.64C767.54 321.58 781.45 336.97 810.32 336.97C835.23 336.97 853.13 322.71 863.77 296.39C876.85 264.07 897.54 239.27 913.75 200.95Z"
        fill="#B4CCC6"
      />
      <path
        d="M836.408 256.169C846.184 249.578 841.26 225.177 825.41 201.669C809.56 178.16 788.786 164.446 779.01 171.037C769.234 177.629 774.158 202.03 790.008 225.538C805.858 249.047 826.632 262.761 836.408 256.169Z"
        fill="#88BFB1"
      />
      <path
        d="M836.47 350.16C834.81 350.16 833.47 348.82 833.47 347.16V256.15C833.47 254.49 834.81 253.15 836.47 253.15C838.13 253.15 839.47 254.49 839.47 256.15V347.16C839.47 348.81 838.13 350.16 836.47 350.16Z"
        fill="#509993"
      />
      <path
        d="M896.569 216.443C917.121 185.961 923.507 154.322 910.833 145.777C898.158 137.231 871.222 155.015 850.67 185.497C830.119 215.979 823.733 247.618 836.407 256.163C849.082 264.709 876.018 246.926 896.569 216.443Z"
        fill="#509993"
      />
      <path
        d="M896.33 350.16H776.69C768.57 350.16 761.97 343.56 761.97 335.44C761.97 302.53 752.89 271.27 735.71 245.03C722.9 225.46 716.13 202.71 716.13 179.23C716.13 162.43 719.53 146.17 726.22 130.91C732.69 116.17 741.92 103.05 753.64 91.92C765.35 80.81 778.91 72.29 793.94 66.61C809.56 60.71 826.05 58.16 842.94 59.04C905.73 62.33 955.75 114.2 956.81 177.15C957.21 201.03 950.63 224.22 937.78 244.2C920.25 271.46 910.98 303.03 910.98 335.51C910.98 343.58 904.41 350.16 896.33 350.16ZM836.41 64.87C806.96 64.87 779.24 75.89 757.77 96.27C734.78 118.09 722.12 147.55 722.12 179.23C722.12 201.54 728.55 223.15 740.72 241.74C758.54 268.96 767.97 301.36 767.97 335.44C767.97 340.25 771.88 344.16 776.69 344.16H896.33C901.1 344.16 904.98 340.28 904.98 335.51C904.98 301.88 914.58 269.18 932.73 240.95C944.94 221.97 951.19 199.94 950.81 177.25C949.8 117.45 902.28 68.16 842.62 65.03C840.55 64.92 838.47 64.87 836.41 64.87Z"
        fill="#5F8199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M499.54 163.56C478.1 153.81 432.79 134.55 426.16 132.78C420.04 131.14 405.19 135.99 396.3 136.52L393.15 135.68C393.3 134.93 393.38 134.15 393.38 133.36C393.38 128.27 390.24 123.92 385.79 122.14C384.4 121.58 378.73 119.46 377.32 118.83C375.33 117.94 373.13 117.44 370.81 117.44C370.61 117.44 370.42 117.46 370.22 117.47L339.49 2.75999C338.98 0.869987 337.04 -0.250008 335.15 0.249992C333.14 0.789992 332.25 3.08999 332.61 6.75999C334.88 30.2 340.35 79.08 342.13 85.71C343.77 91.83 355.91 102.26 360.81 109.69L363.38 119.28C358.33 121.95 354.89 127.25 354.89 133.36C354.89 136.47 355.8 139.37 357.34 141.82L273.37 225.79C271.99 227.17 271.99 229.41 273.37 230.8C274.84 232.27 277.28 231.89 280.28 229.74C299.44 216.05 339.06 187.56 343.92 182.71C348.4 178.23 351.34 161.81 355.32 153.85L362.34 146.83C363.14 147.33 363.98 147.77 364.87 148.13L356.3 606.68H385.32L376.75 148.13C377.3 147.91 384.54 145.11 385.97 144.51C387.35 143.93 388.61 143.1 389.67 142.08L499.59 171.53C501.48 172.04 503.42 170.92 503.93 169.03C504.45 167.01 502.9 165.09 499.54 163.56Z"
        fill="#8FA7B8"
      />
      <path
        d="M366.56 145.81C359.69 145.81 354.1 140.22 354.1 133.35C354.1 126.48 359.69 120.89 366.56 120.89C373.43 120.89 379.02 126.48 379.02 133.35C379.02 140.22 373.43 145.81 366.56 145.81ZM366.56 123.52C361.14 123.52 356.73 127.93 356.73 133.35C356.73 138.77 361.14 143.18 366.56 143.18C371.98 143.18 376.39 138.77 376.39 133.35C376.39 127.93 371.98 123.52 366.56 123.52Z"
        fill="white"
      />
      <path
        d="M371.914 536.608C390.82 507.288 348.981 446.659 278.464 401.189C207.947 355.719 135.456 342.626 116.55 371.946C97.6447 401.266 139.484 461.895 210.001 507.365C280.517 552.835 353.009 565.928 371.914 536.608Z"
        fill="#88BFB1"
      />
      <path
        d="M371.95 537.86C371.72 537.86 371.48 537.8 371.27 537.66L129 381.44C128.42 381.07 128.25 380.29 128.63 379.71C129 379.13 129.78 378.96 130.36 379.34L372.63 535.56C373.21 535.93 373.38 536.71 373 537.29C372.76 537.66 372.36 537.86 371.95 537.86Z"
        fill="#C5E0D6"
      />
      <path
        d="M192.92 490.93C192.4 490.93 191.91 490.6 191.73 490.07C191.51 489.41 191.87 488.71 192.52 488.49L262.76 465.28C263.41 465.06 264.12 465.42 264.34 466.07C264.56 466.73 264.2 467.43 263.55 467.65L193.31 490.86C193.19 490.91 193.05 490.93 192.92 490.93Z"
        fill="#C5E0D6"
      />
      <path
        d="M162.31 454.22C161.76 454.22 161.25 453.85 161.1 453.29C160.92 452.62 161.32 451.94 161.99 451.76L218.59 436.72C219.26 436.54 219.94 436.94 220.12 437.61C220.3 438.28 219.9 438.96 219.23 439.14L162.63 454.18C162.52 454.21 162.42 454.22 162.31 454.22Z"
        fill="#C5E0D6"
      />
      <path
        d="M136.45 416.51C135.88 416.51 135.37 416.12 135.23 415.55C135.07 414.88 135.49 414.2 136.16 414.04L170.72 405.82C171.39 405.66 172.07 406.08 172.23 406.75C172.39 407.42 171.97 408.1 171.3 408.26L136.74 416.48C136.64 416.5 136.54 416.51 136.45 416.51Z"
        fill="#C5E0D6"
      />
      <path
        d="M214.28 436.18C213.68 436.18 213.15 435.75 213.05 435.14L203.39 377.37C203.28 376.69 203.74 376.04 204.42 375.93C205.1 375.82 205.75 376.28 205.86 376.96L215.52 434.73C215.63 435.41 215.17 436.06 214.49 436.17C214.42 436.18 214.35 436.18 214.28 436.18Z"
        fill="#C5E0D6"
      />
      <path
        d="M166.37 405.29C165.78 405.29 165.26 404.88 165.14 404.28L158.37 369.41C158.24 368.73 158.68 368.08 159.36 367.94C160.04 367.81 160.69 368.25 160.83 368.93L167.6 403.8C167.73 404.48 167.29 405.13 166.61 405.27C166.53 405.28 166.45 405.29 166.37 405.29Z"
        fill="#C5E0D6"
      />
      <path
        d="M364.916 553.325C400.021 534.942 389.972 446.505 342.472 355.794C294.972 265.084 228.007 206.451 192.902 224.833C157.797 243.216 167.845 331.654 215.345 422.364C262.846 513.075 329.811 571.708 364.916 553.325Z"
        fill="#C5E0D6"
      />
      <path
        d="M364.9 554.6C364.45 554.6 364.01 554.36 363.79 553.93L206.15 252.87C205.83 252.26 206.07 251.5 206.68 251.18C207.29 250.86 208.05 251.1 208.37 251.71L366 552.77C366.32 553.38 366.08 554.14 365.47 554.46C365.29 554.55 365.09 554.6 364.9 554.6Z"
        fill="white"
      />
      <path
        d="M244.27 324.21C244.2 324.21 244.13 324.2 244.06 324.19L199.09 316.45C198.41 316.33 197.95 315.69 198.07 315.01C198.19 314.33 198.83 313.87 199.51 313.99L244.48 321.73C245.16 321.85 245.62 322.49 245.5 323.17C245.39 323.78 244.86 324.21 244.27 324.21Z"
        fill="white"
      />
      <path
        d="M297.77 426.39C297.73 426.39 297.68 426.39 297.64 426.38L221.66 418.26C220.97 418.19 220.48 417.57 220.55 416.88C220.62 416.19 221.24 415.69 221.93 415.77L297.91 423.89C298.6 423.96 299.09 424.58 299.02 425.27C298.94 425.92 298.4 426.39 297.77 426.39Z"
        fill="white"
      />
      <path
        d="M269.91 373.2C269.86 373.2 269.8 373.2 269.75 373.19L206.55 365.14C205.87 365.05 205.38 364.43 205.47 363.74C205.56 363.05 206.18 362.57 206.87 362.66L270.07 370.71C270.75 370.8 271.24 371.42 271.15 372.11C271.07 372.73 270.53 373.2 269.91 373.2Z"
        fill="white"
      />
      <path
        d="M239.84 316.02C239.66 316.02 239.48 315.98 239.31 315.9C238.68 315.61 238.41 314.86 238.7 314.24L257.96 272.88C258.25 272.25 259 271.98 259.62 272.27C260.25 272.56 260.52 273.3 260.23 273.93L240.97 315.29C240.76 315.75 240.31 316.02 239.84 316.02Z"
        fill="white"
      />
      <path
        d="M293.34 418.19C293.14 418.19 292.93 418.14 292.74 418.04C292.13 417.71 291.91 416.95 292.24 416.34L328.85 349.26C329.18 348.65 329.94 348.43 330.55 348.76C331.16 349.09 331.38 349.85 331.05 350.46L294.44 417.54C294.21 417.96 293.78 418.19 293.34 418.19Z"
        fill="white"
      />
      <path
        d="M265.49 365C265.3 365 265.1 364.95 264.91 364.86C264.3 364.54 264.06 363.79 264.38 363.17L293.77 306.64C294.09 306.03 294.84 305.79 295.46 306.11C296.07 306.43 296.31 307.18 295.99 307.8L266.6 364.33C266.37 364.75 265.94 365 265.49 365Z"
        fill="white"
      />
      <path d="M534.76 366.75H285.64V606.67H534.76V366.75Z" fill="white" />
      <path
        d="M644.48 224.71H395.36L285.64 366.75H534.76L644.48 224.71Z"
        fill="#3B4F66"
      />
      <path
        d="M644.48 224.71H638.59H583.67H577.78H522.86H516.97H462.05H456.16H401.24H395.35L285.64 366.75H291.53H346.45H352.34H407.26H413.15H468.07H473.96H528.88H534.77L539.32 360.86L644.48 224.71ZM634.04 230.6L586 292.79H531.08L579.12 230.6H634.04V230.6ZM573.24 230.6L525.2 292.79H470.28L518.32 230.6H573.24V230.6ZM512.43 230.6L464.39 292.79H409.47L457.51 230.6H512.43V230.6ZM451.62 230.6L403.58 292.79H348.66L396.7 230.6H451.62V230.6ZM296.07 360.86L344.11 298.67H399.03L350.99 360.86H296.07V360.86ZM356.88 360.86L404.92 298.67H459.84L411.8 360.86H356.88V360.86ZM417.69 360.86L465.73 298.67H520.65L472.61 360.86H417.69V360.86ZM478.5 360.86L526.54 298.67H581.46L533.42 360.86H478.5V360.86Z"
        fill="#8FA7B8"
      />
      <path
        d="M420.918 606.667H430.698V499.597H420.918V606.667Z"
        fill="#C6C6C6"
      />
      <path d="M471 499.6H430.7V606.67H471V499.6Z" fill="#5F8199" />
      <path
        d="M378.123 606.668H408.053V481.198H378.123V606.668Z"
        fill="#F6CD7D"
      />
      <path
        d="M453.919 481.203H483.849V461.573H453.919V481.203Z"
        fill="#F6CD7D"
      />
      <path
        d="M378.12 461.57H358.5V606.67H378.12V481.2H453.93V461.57H378.12Z"
        fill="#DEA364"
      />
      <path
        d="M644.48 224.71L534.76 366.75V606.67H554.38V373.45L644.48 256.81V224.71Z"
        fill="#F6CD7D"
      />
      <path
        d="M644.48 224.71L754.2 366.75V606.67H734.58V373.45L644.48 256.81V224.71Z"
        fill="#F6CD7D"
      />
      <path
        d="M644.48 256.81L625.46 281.43L696.54 373.45V606.67H734.58V373.45L644.48 256.81Z"
        fill="#DEA364"
      />
      <path
        d="M696.54 373.45L625.46 281.43L554.38 373.45V606.67H696.54V373.45Z"
        fill="#3B4F66"
      />
      <path
        d="M668.03 420.26V606.67H554.38V383.35L596.95 328.24L668.03 420.26Z"
        fill="#5F8199"
      />
      <path
        d="M420.918 438.481H430.698V389.831H420.918V438.481Z"
        fill="#C6C6C6"
      />
      <path d="M471 389.84H430.7V438.49H471V389.84Z" fill="#5F8199" />
      <path d="M471 389.84H430.7V402.74H471V389.84Z" fill="#3B4F66" />
      <path d="M471 499.6H430.7V512.5H471V499.6Z" fill="#3B4F66" />
      <path
        d="M576.42 607.92C575.73 607.92 575.17 607.36 575.17 606.67V344.92C575.17 344.23 575.73 343.67 576.42 343.67C577.11 343.67 577.67 344.23 577.67 344.92V606.67C577.67 607.36 577.11 607.92 576.42 607.92Z"
        fill="white"
      />
      <path
        d="M636.48 607.92C635.79 607.92 635.23 607.36 635.23 606.67V295.73C635.23 295.04 635.79 294.48 636.48 294.48C637.17 294.48 637.73 295.04 637.73 295.73V606.67C637.73 607.36 637.17 607.92 636.48 607.92Z"
        fill="white"
      />
      <path
        d="M696.54 607.92C695.85 607.92 695.29 607.36 695.29 606.67V373.45C695.29 372.76 695.85 372.2 696.54 372.2C697.23 372.2 697.79 372.76 697.79 373.45V606.67C697.79 607.36 697.24 607.92 696.54 607.92Z"
        fill="white"
      />
      <path
        d="M696.54 500.85H554.38C553.69 500.85 553.13 500.29 553.13 499.6C553.13 498.91 553.69 498.35 554.38 498.35H696.54C697.23 498.35 697.79 498.91 697.79 499.6C697.79 500.29 697.24 500.85 696.54 500.85Z"
        fill="white"
      />
      <path
        d="M696.54 442.81H554.38C553.69 442.81 553.13 442.25 553.13 441.56C553.13 440.87 553.69 440.31 554.38 440.31H696.54C697.23 440.31 697.79 440.87 697.79 441.56C697.79 442.25 697.24 442.81 696.54 442.81Z"
        fill="white"
      />
      <path
        d="M374.47 280.79H379.34C395.22 280.79 410.21 273.43 419.92 260.86L429.54 248.41C433.05 243.86 429.81 237.26 424.06 237.26H407.26C402.5 237.26 398 239.47 395.09 243.24L371.21 274.15C369.12 276.86 371.05 280.79 374.47 280.79Z"
        fill="#5F8199"
      />
      <path
        d="M448.96 267.95H454.27C465.77 267.95 476.63 262.65 483.7 253.58L489.1 246.77C492.11 242.91 489.36 237.27 484.46 237.27H468.13C463.33 237.27 458.79 239.48 455.84 243.27L444.16 258.13C441.04 262.11 443.89 267.95 448.96 267.95Z"
        fill="#5F8199"
      />
      <path
        d="M513.73 259.03H515.59C525.52 259.03 534.92 254.59 541.23 246.93L541.99 246.01C544.86 242.52 542.38 237.27 537.86 237.27H529C524.14 237.27 519.53 239.44 516.45 243.2L509.97 251.07C507.35 254.23 509.61 259.03 513.73 259.03Z"
        fill="#5F8199"
      />
      <path
        d="M337.98 328.2H339.57C351.94 328.2 363.6 322.47 371.16 312.69L371.77 311.84C373.82 309.19 371.93 305.34 368.58 305.34H353.37C349.42 305.34 345.7 307.17 343.28 310.29L334.68 321.49C332.57 324.23 334.52 328.2 337.98 328.2Z"
        fill="#5F8199"
      />
      <path d="M123.34 520.08H101.02V606.67H123.34V520.08Z" fill="#8FA7B8" />
      <path
        d="M198.73 461.57H93.7L3.32001 578.58L6.60001 588.26H111.63L202.01 471.25L198.73 461.57Z"
        fill="#6C8CA6"
      />
      <path
        d="M198.73 461.57H93.7L3.32001 578.58H108.35L198.73 461.57Z"
        fill="#3B4F66"
      />
      <path
        d="M194.98 466.43L197.27 463.45L198.72 461.57H93.7L89.95 466.42L50.38 517.64L47.29 521.64L3.32001 578.57H108.34L151.66 522.5L153.45 520.18L194.98 466.43ZM94.8 466.43H140.04L100.47 517.65H55.23L94.8 466.43ZM96.72 522.5L57.15 573.73H11.91L51.48 522.5H96.72ZM107.24 573.73H62L101.57 522.5H146.81L107.24 573.73ZM150.56 517.65H105.32L144.89 466.43H190.13L150.56 517.65Z"
        fill="#8FA7B8"
      />
      <path
        d="M85.69 500.78H90.3C100.29 500.78 109.72 496.17 115.86 488.29L120.55 482.37C123.17 479.01 120.77 474.11 116.52 474.11H102.33C98.16 474.11 94.22 476.03 91.65 479.32L81.51 492.23C78.81 495.72 81.29 500.78 85.69 500.78Z"
        fill="#5F8199"
      />
      <path
        d="M138.72 493.04H140.34C148.96 493.04 157.14 489.18 162.62 482.52L163.28 481.72C165.78 478.69 163.62 474.12 159.69 474.12H151.99C147.77 474.12 143.77 476.01 141.08 479.27L135.45 486.11C133.18 488.88 135.14 493.04 138.72 493.04Z"
        fill="#5F8199"
      />
      <path d="M251.5 520.08H229.18V606.67H251.5V520.08Z" fill="#8FA7B8" />
      <path
        d="M326.89 461.57H221.86L131.48 578.58L134.76 588.26H239.79L330.17 471.25L326.89 461.57Z"
        fill="#6C8CA6"
      />
      <path
        d="M326.89 461.57H221.86L131.48 578.58H236.51L326.89 461.57Z"
        fill="#3B4F66"
      />
      <path
        d="M323.15 466.43L325.43 463.47L326.89 461.58H221.86L218.11 466.43L178.54 517.65L175.4 521.71L131.47 578.58H236.5L279.82 522.51L281.56 520.26L323.15 466.43ZM222.96 466.43H268.2L228.63 517.65H183.39L222.96 466.43ZM224.88 522.5L185.31 573.73H140.07L179.64 522.5H224.88ZM235.4 573.73H190.16L229.73 522.5H274.97L235.4 573.73ZM278.72 517.65H233.48L273.05 466.43H318.29L278.72 517.65Z"
        fill="#8FA7B8"
      />
      <path
        d="M676.517 113.872C685.652 75.1888 661.698 36.4257 623.015 27.2916C584.332 18.1575 545.569 42.1114 536.435 80.7941C527.301 119.477 551.255 158.24 589.938 167.374C628.62 176.508 667.383 152.554 676.517 113.872Z"
        fill="#88BFB1"
      />
      <path
        d="M606.94 89.06L625.52 46.27H601.04L583.39 99.61H609.28L589.38 148.28L641.59 89.06H606.94Z"
        fill="white"
      />
      <path
        d="M833.91 450.11L869.43 453.02C872.38 453.26 874.9 450.93 874.9 447.97V442.44C874.9 440.73 874.04 439.14 872.62 438.21L859.14 429.36C858.31 428.82 857.35 428.53 856.36 428.53H834.32C831.52 428.53 829.26 430.8 829.26 433.59V445.06C829.26 447.7 831.28 449.9 833.91 450.11Z"
        fill="#DEA364"
      />
      <path
        d="M829.26 433.6V437.85C829.26 440.49 831.28 442.68 833.91 442.89L869.43 445.8C872.08 446.02 874.4 444.15 874.83 441.61C874.6 440.23 873.81 439 872.62 438.22L859.14 429.37C858.32 428.83 857.34 428.54 856.36 428.54H834.32C831.52 428.53 829.26 430.8 829.26 433.6Z"
        fill="#F6CD7D"
      />
      <path
        d="M1080.76 372.13C1081.28 365.81 1121.08 363.93 1169.64 367.94C1218.2 371.95 1257.86 379.63 1256.63 386.66C1255.31 394.17 1249.51 401.89 1214.28 399.34C1165.68 395.81 1080.24 378.45 1080.76 372.13Z"
        fill="#DEA364"
      />
      <path
        d="M820.72 514.51C805.47 514.51 793.11 535.14 793.11 560.59C793.11 586.04 805.47 606.67 820.72 606.67H851.06V514.51H820.72V514.51Z"
        fill="#2D374D"
      />
      <path
        d="M1223.53 514.51C1208.28 514.51 1195.92 535.14 1195.92 560.59C1195.92 586.04 1208.28 606.67 1223.53 606.67H1253.87V514.51H1223.53V514.51Z"
        fill="#2D374D"
      />
      <path
        d="M1014.28 514.51C999.03 514.51 986.67 535.14 986.67 560.59C986.67 586.04 999.03 606.67 1014.28 606.67H1044.62V514.51H1014.28V514.51Z"
        fill="#2D374D"
      />
      <path
        d="M851.07 606.67C866.319 606.67 878.68 586.039 878.68 560.59C878.68 535.141 866.319 514.51 851.07 514.51C835.821 514.51 823.46 535.141 823.46 560.59C823.46 586.039 835.821 606.67 851.07 606.67Z"
        fill="#3B4F66"
      />
      <path
        d="M1305.53 542.64C1307.01 504.66 1303.86 485.73 1298.28 451.74C1296.92 446.59 1294.72 440.87 1290.5 435.55C1277.58 419.25 1262.01 403.97 1252.77 395.7C1237.29 381.84 1221.17 374.55 1199.68 371.59C1147.79 364.44 1084.66 362.82 1035.64 364.92C994.83 366.67 959.68 368.11 936.88 381.07C916.04 392.92 869.45 432.02 852.08 449.25C836.81 452.51 817.65 459.82 794.98 484.45C778.94 501.89 782 535.23 782 550.88C782 572.97 798.09 580.62 817.28 580.62C894.56 580.62 1218.89 580.62 1275.37 580.62C1297.77 580.63 1304.62 566.24 1305.53 542.64Z"
        fill="#F6CD7D"
      />
      <path
        d="M950.06 378.49C919.89 392.39 873.63 434.34 861.82 448.71H1037.4C1052.72 427.31 1081.73 395.29 1100.76 378.49H950.06Z"
        fill="#3B4F66"
      />
      <path
        d="M1088.32 403.22C1071.93 422.51 1057.29 445.16 1051.16 457.82C1051.16 457.82 1188.59 448.05 1228.2 443.82C1248.06 441.7 1252.11 431.48 1248.85 421.3C1243.51 404.65 1231.84 386.06 1219.29 382.53C1203.6 378.11 1160.23 377.16 1137.9 378.9C1122.82 380.08 1102.67 386.33 1088.32 403.22Z"
        fill="#3B4F66"
      />
      <path
        d="M971.73 580.63H794.77C790.04 580.63 787.14 577.55 785.25 573.22C782.33 566.54 776.31 551.92 776.45 545.02C776.56 539.57 779.01 521.24 782.25 520.97C784.92 520.74 813.98 532.09 829.9 532.09C856.74 532.09 903.12 532.09 927.72 532.09C942.11 532.09 986.19 516.82 989.36 522.32C991.56 526.14 979.9 535.87 980.06 545.01C980.2 552.57 981.04 570.06 981.04 570.06C981.76 575.66 977.38 580.63 971.73 580.63Z"
        fill="#DEA364"
      />
      <path
        d="M952.31 571.49H796.21L804.27 552.08C805.75 548.52 809.23 546.2 813.09 546.2H928.21C931.18 546.2 933.98 547.58 935.79 549.94L952.31 571.49Z"
        fill="#2D374D"
      />
      <path
        d="M910.42 495.56H855.17L816.6 489.13L811.95 507.14L847.87 523.87H903.12L947.67 507.14L952.31 489.13L910.42 495.56Z"
        fill="#DEA364"
      />
      <path
        d="M816.6 489.13L811.95 507.15L787.76 495.89C786.28 487.45 790.68 481.42 794.03 477.56C796.95 474.23 811.47 464.8 821.82 461.5C822.14 461.83 803.64 471.73 807.09 476.61L816.6 489.13Z"
        fill="#6C8CA6"
      />
      <path
        d="M1077.39 580.63C1079.4 573.68 1080.52 565.88 1080.52 557.65C1080.52 528.39 1066.3 504.66 1048.77 504.66C1031.23 504.66 1017.01 528.38 1017.01 557.65C1017.01 565.88 1018.14 573.68 1020.14 580.63H1077.39Z"
        fill="#2D374D"
      />
      <path
        d="M1044.63 606.67C1059.88 606.67 1072.24 586.039 1072.24 560.59C1072.24 535.141 1059.88 514.51 1044.63 514.51C1029.38 514.51 1017.02 535.141 1017.02 560.59C1017.02 586.039 1029.38 606.67 1044.63 606.67Z"
        fill="#3B4F66"
      />
      <path
        d="M1044.63 590.54C1054.54 590.54 1062.58 577.131 1062.58 560.59C1062.58 544.049 1054.54 530.64 1044.63 530.64C1034.72 530.64 1026.68 544.049 1026.68 560.59C1026.68 577.131 1034.72 590.54 1044.63 590.54Z"
        fill="white"
      />
      <path
        d="M1277.38 580.63C1279.91 580.63 1286.91 579.71 1287.25 578.38C1288.87 572.01 1289.77 565.01 1289.77 557.65C1289.77 528.39 1275.55 504.66 1258.02 504.66C1240.48 504.66 1226.27 528.38 1226.27 557.65C1226.27 565.88 1227.4 573.68 1229.4 580.63H1277.38Z"
        fill="#2D374D"
      />
      <path
        d="M1253.87 606.67C1269.12 606.67 1281.48 586.039 1281.48 560.59C1281.48 535.141 1269.12 514.51 1253.87 514.51C1238.62 514.51 1226.26 535.141 1226.26 560.59C1226.26 586.039 1238.62 606.67 1253.87 606.67Z"
        fill="#3B4F66"
      />
      <path
        d="M1253.87 590.54C1263.78 590.54 1271.82 577.131 1271.82 560.59C1271.82 544.049 1263.78 530.64 1253.87 530.64C1243.96 530.64 1235.92 544.049 1235.92 560.59C1235.92 577.131 1243.96 590.54 1253.87 590.54Z"
        fill="white"
      />
      <path
        d="M944.4 385.79C917.54 402.74 888.95 425.56 878.32 438.49C878.32 438.49 992.54 434.16 1031.23 419.53C1050.23 412.34 1049.24 396.88 1048.39 385.79H944.4Z"
        fill="#8FA7B8"
      />
      <path
        d="M850.93 470.5C850.36 479.37 852.49 492.03 849.94 492.01C843.62 491.97 816.86 477.92 816.86 473.78C816.86 469.64 846.77 452.14 855.17 452.14C858.48 452.14 851.87 455.96 850.93 470.5Z"
        fill="#F6CD7D"
      />
      <path
        d="M1002.7 486.48L947.67 507.14L952.32 489.13L971.5 474.09C976.83 469.91 982.79 467.23 988.62 466.4L1022.69 461.51C1025.6 461.09 1026.71 464.49 1024.55 467.2L1018.7 474.52C1014.3 480.02 1008.66 484.24 1002.7 486.48Z"
        fill="#6C8CA6"
      />
      <path
        d="M991.62 490.64L947.67 507.14L951.38 492.75L966.7 480.74C970.96 477.4 975.72 475.26 980.37 474.59L1007.58 470.68C1009.9 470.35 1010.79 473.06 1009.06 475.22L1004.39 481.07C1000.89 485.48 996.38 488.85 991.62 490.64Z"
        fill="white"
      />
      <path
        d="M815.64 492.76L811.95 507.15L792.98 498.61C788.34 486.96 798.09 474.84 806.56 470.67C807.65 471.7 802.81 473.67 806.03 478.9L815.64 492.76Z"
        fill="white"
      />
      <path
        d="M974.03 559.01H942.72L952.32 571.49H973.49C976.83 571.49 979.41 568.56 978.98 565.25L978.71 563.12C978.4 560.77 976.4 559.01 974.03 559.01Z"
        fill="white"
      />
      <path
        d="M801.43 559.01L796.21 571.48H784.5C783.06 568.13 781.21 563.58 779.63 559.01H801.43Z"
        fill="white"
      />
      <path
        d="M1161.71 469.32L1143.38 470.5C1141.87 470.6 1140.56 469.45 1140.46 467.94L1140.35 466.3C1140.25 464.79 1141.4 463.48 1142.91 463.38L1161.24 462.2C1162.75 462.1 1164.06 463.25 1164.16 464.76L1164.27 466.4C1164.37 467.91 1163.22 469.22 1161.71 469.32Z"
        fill="#DEA364"
      />
      <path
        d="M1243.99 462.21L1225.71 464.02C1224.2 464.17 1222.86 463.07 1222.71 461.56L1222.55 459.92C1222.4 458.41 1223.5 457.07 1225.01 456.92L1243.29 455.11C1244.8 454.96 1246.14 456.06 1246.29 457.57L1246.45 459.21C1246.6 460.71 1245.5 462.06 1243.99 462.21Z"
        fill="#DEA364"
      />
      <path
        d="M1302.49 478.45H1280.63C1279.06 478.45 1277.94 476.93 1278.4 475.43L1284.34 456.33C1285.37 453.03 1288.28 450.66 1291.73 450.31L1297.72 449.72C1297.92 450.4 1298.11 451.07 1298.29 451.73C1299.91 461.64 1301.33 470.27 1302.49 478.45Z"
        fill="#DEA364"
      />
      <path
        d="M1203.99 385.79C1208.77 385.79 1214.05 387.81 1215.86 392.28C1218.42 398.59 1220.42 403.56 1221.37 409.73C1222.13 414.68 1218.55 418.15 1209.36 418.15C1184.98 418.15 1158.12 417.23 1141.01 414.34C1135.58 413.42 1126.33 410.49 1122.21 406.5C1119.01 403.4 1119.13 396.58 1119.96 392.65C1120.85 388.45 1122.07 385.79 1131.99 385.79C1145.11 385.79 1198.27 385.79 1203.99 385.79Z"
        fill="#6C8CA6"
      />
      <path
        d="M1253.87 534.98C1251.96 534.98 1250.13 535.57 1248.44 536.64C1254.23 540.3 1258.35 549.64 1258.35 560.59C1258.35 571.54 1254.23 580.88 1248.44 584.54C1250.13 585.61 1251.96 586.2 1253.87 586.2C1262.35 586.2 1269.22 574.74 1269.22 560.59C1269.21 546.45 1262.34 534.98 1253.87 534.98Z"
        fill="#8FA7B8"
      />
      <path
        d="M1044.62 534.98C1042.71 534.98 1040.88 535.57 1039.19 536.64C1044.98 540.3 1049.1 549.64 1049.1 560.59C1049.1 571.54 1044.98 580.88 1039.19 584.54C1040.88 585.61 1042.71 586.2 1044.62 586.2C1053.1 586.2 1059.97 574.74 1059.97 560.59C1059.97 546.45 1053.1 534.98 1044.62 534.98Z"
        fill="#8FA7B8"
      />
      <path
        d="M1202.25 560.01H1085.61L1084.61 455.26L1086.61 455.24L1087.59 558.01H1202.25C1210.73 558.01 1217.89 551.98 1219.29 543.67C1221.88 528.24 1228.76 508.01 1246.75 499.3C1254.56 495.52 1259.28 487.1 1258.5 478.35C1256.86 459.97 1252.68 440.89 1246.05 421.63L1247.94 420.98C1254.62 440.39 1258.84 459.63 1260.49 478.17C1261.34 487.75 1256.17 496.96 1247.62 501.1C1230.39 509.44 1223.77 529.03 1221.26 544C1219.7 553.28 1211.7 560.01 1202.25 560.01Z"
        fill="#3B4F66"
      />
      <path
        d="M1167.91 559.14L1165.93 558.89C1171.37 514.9 1174.45 485.54 1171.45 448.79L1173.44 448.63C1176.45 485.57 1173.36 515.02 1167.91 559.14Z"
        fill="#3B4F66"
      />
      <path
        d="M884.73 521.81C891.407 521.81 896.82 516.397 896.82 509.72C896.82 503.043 891.407 497.63 884.73 497.63C878.053 497.63 872.64 503.043 872.64 509.72C872.64 516.397 878.053 521.81 884.73 521.81Z"
        fill="#536982"
      />
      <path
        d="M878.68 521.71C872.07 521.71 866.69 516.33 866.69 509.72C866.69 503.11 872.07 497.73 878.68 497.73C885.29 497.73 890.67 503.11 890.67 509.72C890.67 516.33 885.29 521.71 878.68 521.71ZM878.68 499.73C873.17 499.73 868.69 504.21 868.69 509.72C868.69 515.23 873.17 519.71 878.68 519.71C884.19 519.71 888.67 515.23 888.67 509.72C888.67 504.21 884.19 499.73 878.68 499.73Z"
        fill="#3B4F66"
      />
      <path
        d="M1111.15 450.11L1075.63 453.02C1072.68 453.26 1070.16 450.93 1070.16 447.97V442.44C1070.16 440.73 1071.02 439.14 1072.44 438.21L1085.92 429.36C1086.75 428.82 1087.71 428.53 1088.7 428.53H1110.74C1113.54 428.53 1115.8 430.8 1115.8 433.59V445.06C1115.8 447.7 1113.78 449.9 1111.15 450.11Z"
        fill="#DEA364"
      />
      <path
        d="M1115.8 433.59V437.84C1115.8 440.47 1113.78 442.67 1111.15 442.88L1075.63 445.79C1072.98 446.01 1070.66 444.14 1070.23 441.6C1070.46 440.22 1071.25 438.99 1072.44 438.21L1085.92 429.36C1086.74 428.82 1087.72 428.53 1088.7 428.53H1110.74C1113.54 428.53 1115.8 430.8 1115.8 433.59Z"
        fill="#F6CD7D"
      />
      <path
        d="M888.23 509.72C888.23 511.65 886.67 513.22 884.73 513.22H622.18C605.42 513.22 591.78 526.86 591.78 543.62V640.4C591.78 661.02 575.01 677.8 554.38 677.8C533.75 677.8 516.98 661.02 516.98 640.4V596.11C516.98 579.99 503.86 566.87 487.74 566.87H430.7V559.87H487.74C507.72 559.87 523.98 576.12 523.98 596.11V640.4C523.98 657.17 537.62 670.8 554.38 670.8C571.14 670.8 584.78 657.16 584.78 640.4V543.62C584.78 523 601.56 506.22 622.18 506.22H884.73C886.67 506.22 888.23 507.78 888.23 509.72Z"
        fill="#2D374D"
      />
      <path
        d="M1319.43 607.92H1.69C1 607.92 0.440002 607.36 0.440002 606.67C0.440002 605.98 1 605.42 1.69 605.42H1319.43C1320.12 605.42 1320.68 605.98 1320.68 606.67C1320.68 607.36 1320.12 607.92 1319.43 607.92Z"
        fill="#343959"
      />
    </StyledSVG>
  );
}
