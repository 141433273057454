export const LATEST_STATUS_REQUEST_STATE = "LATEST_STATUS_REQUEST_STATE";
export const LAST_SETTLED_TRANSACTION_REQUEST_STATE =
  "LAST_SETTLED_TRANSACTION_REQUEST_STATE";
export const GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE =
  "GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE";
export const GET_CHARGING_STATION_SETTINGS_REQUEST_STATE =
  "GET_CHARGING_STATION_SETTINGS_REQUEST_STATE";
export const SET_CHARGING_STATION_SETTINGS_REQUEST_STATE =
  "SET_CHARGING_STATION_SETTINGS_REQUEST_STATE";
export const SET_REMOTE_START_REQUEST_STATE = "SET_REMOTE_START_REQUEST_STATE";
export const SET_REMOTE_STOP_REQUEST_STATE = "SET_REMOTE_STOP_REQUEST_STATE";
export const GET_CHARGING_STATION_LATEST_IN_PROGRESS_REQUEST_STATE =
  "GET_CHARGING_STATION_LATEST_IN_PROGRESS_REQUEST_STATE";
