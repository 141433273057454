import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  createLoadBalancer,
  getLoadBalancers,
} from "../../store/actions/loadBalancer";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  classesx as classesxReport,
  IconButtonRoot as IconButtonRootReport,
} from "./Report";
import { RootDiv as RootDivReport } from "./Report";
import { DashboarClasses, GridRoot as GridRootDashboard } from "./Dashboard";
import {
  SettingClasses,
  SettingdDiv,
  ButtonRoot,
  TextFieldRoot,
} from "./Settings";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  loadbalancerClasses,
  LoadbalancerDiv,
  ListRoot,
  DialogRoot,
} from "./LoadBalancerDetail";
import {
  AccountsettingsDiv,
  RootH2,
  accountpageClasses,
} from "./AccountSettingsPage";
import Background from "../common/background/background";

function LoadBalancersList() {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [maxPower, setMaxPower] = useState(0);
  const [open, setOpen] = React.useState(false);
  const lbs = useSelector((state) => state.loadBalancer.loadBalancers);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleMaxPowerChange(e) {
    setMaxPower(e.target.value);
  }

  useEffect(() => {
    dispatch(getLoadBalancers());
  }, []);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${loadbalancerClasses.GridRoot_container_accountsettings}`}
        >
          <AccountsettingsDiv className={accountpageClasses.pagetitleblock}>
            <RootH2 className={`${accountpageClasses.pagetitleblockh2}`}>
              {t("text.dynamic_load_management")}
            </RootH2>
          </AccountsettingsDiv>
          <LoadbalancerDiv className={loadbalancerClasses.section_3}>
            <SettingdDiv
              className={`${SettingClasses.CardBody} ${loadbalancerClasses.loadbalancerlist}`}
              sx={{
                minHeight: "130px!important",
                [theme.breakpoints.down("xl")]: {
                  minHeight: "115px!important",
                },
                [theme.breakpoints.down("sm")]: {
                  minHeight: "90px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  minHeight: "75px!important",
                },
              }}
            >
              <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
                <ListRoot
                  className={loadbalancerClasses.ListRoot}
                  component="nav"
                >
                  {lbs.length > 0 ? (
                    lbs.map((lb, i) => (
                      <>
                        <ListItem
                          key={lb.uid}
                          button
                          onClick={() => {
                            history.push(`/vlb/${lb.uid}`);
                          }}
                        >
                          {lb.name}
                        </ListItem>
                      </>
                    ))
                  ) : (
                    <>
                      <p className="Noticetext" style={{ paddingLeft: "0px" }}>
                        {t("text.no_lb_created_yet")}
                      </p>
                    </>
                  )}
                </ListRoot>
              </SettingdDiv>
            </SettingdDiv>
          </LoadbalancerDiv>
        </GridRootDashboard>
      </RootDivReport>
      <IconButtonRootReport
        aria-label="Add_popup"
        className={`${classesxReport.IconButtonRoot}`}
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: "16px",
          right: "16px",
        }}
        size="large"
      >
        <AddCircleOutlineRoundedIcon />
      </IconButtonRootReport>
      <DialogRoot
        open={open}
        className={loadbalancerClasses.charginstation_dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <h4 className="DialogRooth4">{t("text.create_lb")}</h4>
        <div className="DialogRootinput">
          <TextFieldRoot
            autoFocus
            required
            margin="dense"
            id="name"
            label={t("text.lb_name")}
            type="name"
            onChange={handleNameChange}
            fullWidth
            className={SettingClasses.TextFieldRoot}
            sx={{
              margin: "0 0 20px 0",
              marginRight: "0px!important",
              [theme.breakpoints.down("lg")]: {
                margin: "0 0 15px 0",
              },
            }}
            variant="outlined"
            autoComplete="on"
          />
          <TextFieldRoot
            autoFocus
            required
            margin="dense"
            id="max-power"
            label={t("text.max_power_limit")}
            type="number"
            onChange={handleMaxPowerChange}
            fullWidth
            className={SettingClasses.TextFieldRoot}
            sx={{
              margin: "0 0 20px 0",
              [theme.breakpoints.down("lg")]: {
                margin: "0 0 10px 0",
              },
            }}
            variant="outlined"
            autoComplete="on"
          />
        </div>
        <div className="btn_block">
          <div className="btn_block_wrapper">
            <ButtonRoot
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={`${SettingClasses.ButtonRoot}`}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px!important",
                  padding: "10px 25px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  padding: "8px 20px!important",
                },
              }}
            >
              {t("button.cancel")}
            </ButtonRoot>
          </div>
          <div className="btn_block_wrapper">
            <ButtonRoot
              sx={{
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px!important",
                  padding: "10px 25px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  padding: "8px 20px!important",
                },
              }}
              variant="contained"
              color="primary"
              className={`${SettingClasses.ButtonRoot}`}
              onClick={() => {
                dispatch(createLoadBalancer(name, maxPower, true));
                handleClose();
              }}
            >
              {t("button.create")}
            </ButtonRoot>
          </div>
        </div>
      </DialogRoot>
    </>
  );
}

export default LoadBalancersList;
