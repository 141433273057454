import React from "react";
import { useTheme } from "@mui/material";

const DividerWithText = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        overflowWrap: "break-word",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <div style={{ borderBottom: "2px solid lightgray", width: "10%" }} />
      <span
        style={{
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          fontWeight: 500,
          fontSize: 12,
          color: "grey",
        }}
      >
        {children}
      </span>
      <div style={{ borderBottom: "2px solid lightgray", width: "10%" }} />
    </div>
  );
};

export default DividerWithText;
