import { useState, useEffect } from "react";
import {
  Button,
  DialogContent,
  Tooltip,
  Select,
  useTheme,
} from "@mui/material";
import firebase from "firebase/app";
import "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { getChargingStations } from "../../store/actions/chargingStation";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFirebase } from "react-redux-firebase";
import { signOut } from "../../store/actions/auth";
import { styled } from "@mui/material/styles";
import { classesx as classesxReport } from "./Report";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
} from "./Report";
import {
  DashboarClasses,
  DashboardDiv,
  GridRoot as GridRootDashboard,
} from "./Dashboard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SettingClasses, SettingdDiv, RootH3, TextFieldRoot } from "./Settings";
import { PVSettingsdDiv, PVSettingsClasses } from "./PVSettings";
import Background from "../common/background/background";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import { InfoRounded } from "@mui/icons-material";
import {
  getUserAccountSettings,
  updateUserAccountSettings,
} from "../../lib/api";
export const PREFIX = "accountsettings";
export const accountpageClasses = {
  GridRoot_container_accountsettings: `${PREFIX}-GridRoot_container`,
  pagetitleblock: `${PREFIX}-pagetitleblock`,
  pagetitleblockh2: `${PREFIX}-pagetitleblockh2`,
  chargingStationcard: `${PREFIX}-chargingStationcard`,
  chargingStationcard_cardbody: `${PREFIX}-chargingStationcard_cardbody`,
};

export const AccountsettingsDiv = styled("div")(({ theme }) => ({
  [`&.${accountpageClasses.pagetitleblock}`]: {
    marginBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  [`&.${accountpageClasses.chargingStationcard}`]: {
    [theme.breakpoints.down("lg")]: {
      [`& .test_card_left`]: {
        width: "calc(100% - 40px)!important",
      },
      [`& .test_card_right`]: {
        marginTop: "0px!important",
        width: "unset!important",
      },
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot:first-of-type`]:
        {
          marginRight: "15px!important",
        },
    },
    [theme.breakpoints.down("sm")]: {
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot:first-of-type`]:
        {
          marginRight: "0px!important",
          marginBottom: "15px",
        },
      [`& .settingpage-cardTop_content .settingpage-TextFieldRoot`]: {
        width: "100%",
      },
      [`& .test_card_left`]: {
        width: "100%!important",
      },
      [`& .test_card_right`]: {
        marginTop: "10px!important",
        width: "100%!important",
        justifyContent: "flex-end",
      },
    },
  },
}));
export const RootH2 = styled("h2")(({ theme }) => ({
  [`&.${accountpageClasses.pagetitleblockh2}`]: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.2,
    color: "#1C1C1C",
    textTransform: "capitalize",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
}));

function UpdateUsername() {
  const theme = useTheme();
  const { t } = useTranslation();
  const user = firebase.auth().currentUser;
  const username = user.displayName;

  const [username_, setUsername] = useState(username);
  return (
    <>
      <PVSettingsdDiv
        className={PVSettingsClasses.PVsurplus_card_body}
        sx={{
          width: "100%",
          margin: "15px 0px 35px 0px",
          [theme.breakpoints.down("sm")]: {
            margin: "10px 0px 10px 0px",
          },
        }}
      >
        <RootH3
          className={`${SettingClasses.setting_card_h3}`}
          sx={{
            marginBottom: "20px!important",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              marginBottom: "15px!important",
            },
            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px!important",
            },
          }}
        >
          {t("text.update_username")}
          <Tooltip
            sx={{ marginLeft: "8px" }}
            title={t("text.username_update_desc")}
          >
            <InfoRounded />
          </Tooltip>
        </RootH3>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <TextField
            placeholder="Username"
            value={username_}
            onChange={(e) => setUsername(e.target.value)}
          />
          <LoadingButton
            variant="outlined"
            sx={{
              marginTop: "15px",
              borderRadius: "35px",
            }}
            onClick={() => {
              user
                .updateProfile({
                  displayName: username_,
                })
                .then(() => {
                  toast.success(t("text.username_updated"));
                })
                .catch((err) => {
                  toast.error(t("error.username_update_error"));
                });
            }}
          >
            {t("button.save")}
          </LoadingButton>
        </Box>
      </PVSettingsdDiv>
    </>
  );
}

function AddressForm({ lang }) {
  const [streetName, setStreetName] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const theme = useTheme();
  const userAcc = useQuery(["user_acc"], getUserAccountSettings);
  const { t } = useTranslation();
  const userAccMutation = useMutation(["user_acc"], updateUserAccountSettings);

  const userAccToast = (statusCode) => {
    if (statusCode == 200) {
      return toast.success(t("text.user_acc_settings_saved"));
    } else if (statusCode == 500) {
      return toast.error(t("error.internal_server_error"));
    } else {
      return toast.error(t("error.unknown_error") + statusCode);
    }
  };

  useEffect(() => {
    if (!userAcc.isLoading) {
      setStreetName(userAcc.data?.address?.street_name);
      setBuildingNumber(userAcc.data?.address?.building_number);
      setPostalCode(userAcc.data?.address?.postal_code);
      setCity(userAcc.data?.address?.city);
      setCountry(userAcc.data?.address?.country);
    }
  }, [userAcc.isLoading]);

  useEffect(() => {
    if (userAccMutation.isSuccess) {
      userAccToast(userAccMutation.data.status);
    } else if (userAccMutation.isError) {
      userAccToast(userAccMutation.error.response.status);
    }
  }, [userAccMutation.isLoading]);
  return (
    <>
      <PVSettingsdDiv
        className={PVSettingsClasses.PVsurplus_card_body}
        sx={{
          width: "100%",
          margin: "15px 0px 35px 0px",
          [theme.breakpoints.down("sm")]: {
            margin: "10px 0px 10px 0px",
          },
        }}
      >
        <RootH3
          className={`${SettingClasses.setting_card_h3}`}
          sx={{
            marginBottom: "20px!important",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              marginBottom: "15px!important",
            },
            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px!important",
            },
          }}
        >
          {t("text.address")}
          <Tooltip sx={{ marginLeft: "8px" }} title={t("text.address_desc")}>
            <InfoRounded />
          </Tooltip>
        </RootH3>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <div>
            <TextField
              required
              variant="outlined"
              placeholder={t("text.street_name")}
              value={streetName}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => setStreetName(e.target.value)}
            />
            <TextField
              required
              variant="outlined"
              placeholder={t("text.house_number")}
              value={buildingNumber}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => setBuildingNumber(e.target.value)}
            />
          </div>
          <div>
            <TextField
              required
              variant="outlined"
              placeholder={t("text.postal_code")}
              value={postalCode}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => setPostalCode(e.target.value)}
            />
            <TextField
              required
              variant="outlined"
              placeholder={t("text.city")}
              value={city}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div>
            <TextField
              required
              variant="outlined"
              placeholder={t("text.country")}
              value={country}
              inputProps={{ maxLength: 30 }}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <LoadingButton
            variant="outlined"
            disabled={
              !streetName || !buildingNumber || !postalCode || !city || !country
            }
            loading={userAccMutation.isLoading}
            sx={{
              marginTop: "15px",
              borderRadius: "35px",
            }}
            onClick={() => {
              userAccMutation.mutate({
                lang,
                streetName,
                buildingNumber,
                postalCode,
                city,
                country,
              });
            }}
          >
            {t("button.save")}
          </LoadingButton>
        </Box>
      </PVSettingsdDiv>
    </>
  );
}

function LanguagePreferences({ handleChangeLang, selectedLang, t }) {
  const theme = useTheme();
  const languages = [
    {
      lang_shorthand: "de",
      lang_name: "German",
    },
    {
      lang_shorthand: "en",
      lang_name: "English",
    },
    {
      lang_shorthand: "fr",
      lang_name: "French",
    },
  ];

  return (
    <>
      <RootDivReport
        className={classesxReport.form_row}
        sx={{
          margin: "20px 0 20px 0",
          [theme.breakpoints.down("lg")]: {
            margin: "15px 0 20px 0",
          },
          [theme.breakpoints.down("md")]: {
            margin: "10px 0 20px 0",
          },
        }}
      >
        <DashboardDiv
          className={DashboarClasses.Input_Wrapper}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%!important",
            },
          }}
        >
          <RootDivReport
            className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
            sx={{
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "flex-start!important",
              },
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px!important",
              },
            }}
          >
            <RootH3
              className={`${SettingClasses.setting_card_h3}`}
              sx={{
                marginBottom: "20px!important",
                marginRight: "20px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "15px!important",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "10px!important",
                },
              }}
            >
              {`${t("text.language")}`}
            </RootH3>
            <RootDivReport className={classesxReport.selct_dropdown}>
              <FormControlRootReport className={classesxReport.FormControlRoot}>
                <Select
                  labelId="language-select-outlined-label"
                  id="language-select-outlined"
                  value={selectedLang}
                  onChange={handleChangeLang}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {languages.map((lang) => (
                    <MenuItemRootReport
                      className={classesxReport.MenuItemRoot}
                      key={lang.lang_shorthand}
                      value={lang.lang_shorthand}
                    >
                      {lang.lang_name}
                    </MenuItemRootReport>
                  ))}
                </Select>
              </FormControlRootReport>
            </RootDivReport>
          </RootDivReport>
        </DashboardDiv>
      </RootDivReport>
    </>
  );
}

function UpdatePassword({ t }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  function handlePasswordChange(e) {
    setNewPassword(e.target.value);
  }

  function handlePasswordConfirmChange(e) {
    setNewPasswordConfirm(e.target.value);
  }

  const passwordUpdateErrorToast = (error) => {
    switch (error.code) {
      case "local/password-fields-empty":
        return toast.error(t("error.password_fields_empty"));
      case "local/passwords-dont-match":
        return toast.error(t("error.passwords_do_not_match"));
      case "auth/weak-password":
        return toast.error(t("error.weak_password"));
      case "auth/requires-recent-login":
        return toast.error(t("error.require_recent_login"));
    }
  };

  return (
    <>
      <PVSettingsdDiv
        className={PVSettingsClasses.PVsurplus_card_body}
        sx={{
          width: "100%",
          margin: "15px 0px 15px 0px",
          [theme.breakpoints.down("sm")]: {
            margin: "10px 0px 10px 0px",
          },
        }}
      >
        <RootH3
          className={`${SettingClasses.setting_card_h3}`}
          sx={{
            marginBottom: "20px!important",
            [theme.breakpoints.down("md")]: {
              marginBottom: "15px!important",
            },
            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px!important",
            },
          }}
        >
          {t("text.update_password")}
        </RootH3>
        <form action="#0">
          <PVSettingsdDiv className={PVSettingsClasses.loginData_block}>
            <TextFieldRoot
              value={newPassword}
              type="password"
              label={t("text.password")}
              onChange={handlePasswordChange}
              variant="outlined"
              placeholder={t("text.password")}
              className={SettingClasses.TextFieldRoot}
              autoComplete="current-password"
              sx={{
                margin: "0px 0px 15px 0px!important",
                width: "100%",
              }}
            />
            <TextFieldRoot
              value={newPasswordConfirm}
              type="password"
              label={t("text.confirm_password")}
              onChange={handlePasswordConfirmChange}
              variant="outlined"
              placeholder={t("text.confirm_password")}
              className={SettingClasses.TextFieldRoot}
              sx={{ width: "100%" }}
              autoComplete="current-password"
            />
            <LoadingButton
              variant="contained"
              color="primary"
              className={`${SettingClasses.ButtonRoot}`}
              loading={loading}
              sx={{
                marginTop: "15px",
                borderRadius: "35px",
              }}
              onClick={() => {
                if (newPassword !== "") {
                  if (newPassword !== newPasswordConfirm) {
                    passwordUpdateErrorToast({
                      code: "local/passwords-dont-match",
                    });
                  } else {
                    setLoading(true);
                    const user = firebase.auth().currentUser;
                    user
                      .updatePassword(newPassword)
                      .catch((error) => {
                        passwordUpdateErrorToast(error);
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }
                } else if (newPassword === "" || newPasswordConfirm === "") {
                  passwordUpdateErrorToast({
                    code: "local/password-fields-empty",
                  });
                }
              }}
            >
              {t("button.save")}
            </LoadingButton>
          </PVSettingsdDiv>
        </form>
        <Toaster />
      </PVSettingsdDiv>
    </>
  );
}

function AccountSettingsPage() {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentUser = getFirebase().auth().currentUser;
  const theme = useTheme();
  const [showUpdatePasswordSection, setShowUpdatePasswordSection] =
    useState(false);
  const saveDisabled = useSelector((state) => state.app.saveDisabled);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const userPreferences = useSelector((state) => state.user.userPreferences);

  function handleChangeLang(e) {
    setSelectedLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  const handleDialogClose = (event) => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    setShowUpdatePasswordSection(
      firebase
        .auth()
        .currentUser.providerData.every(
          (data) => data.providerId === "password"
        )
    );
  }, []);

  useEffect(() => {
    if (userPreferences !== null) {
      setSelectedLang(userPreferences.lang);
      i18n.changeLanguage(userPreferences.lang);
    }
  }, [userPreferences]);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${accountpageClasses.GridRoot_container_accountsettings}`}
        >
          <AccountsettingsDiv className={accountpageClasses.pagetitleblock}>
            <RootH2 className={`${accountpageClasses.pagetitleblockh2}`}>
              {t("text.account_settings")}
            </RootH2>
          </AccountsettingsDiv>
          <AccountsettingsDiv
            className={accountpageClasses.chargingStationcard}
          >
            <SettingdDiv
              className={`${SettingClasses.CardBody} ${accountpageClasses.chargingStationcard_cardbody}`}
              sx={{
                minHeight: "440px!important",
                [theme.breakpoints.down("lg")]: {
                  minHeight: "380px!important",
                },
                [theme.breakpoints.down("sm")]: {
                  minHeight: "430px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  minHeight: "400px!important",
                },
              }}
            >
              {showUpdatePasswordSection ? <UpdatePassword t={t} /> : null}
              <LanguagePreferences
                handleChangeLang={handleChangeLang}
                selectedLang={selectedLang}
                t={t}
              />
              <UpdateUsername />
              <AddressForm lang={selectedLang} />
              <SettingdDiv
                sx={{
                  width: "100%",
                  padding: "20px 20px!important",
                  borderRadius: "20px",
                  border: "1px solid rgba(255, 0, 0, 0.7)!important",
                }}
              >
                <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      const user = firebase.auth().currentUser;
                      user.delete().catch((error) => {
                        if (error.code === "auth/requires-recent-login") {
                          // display alertbox with signout option to reauthenticate
                          setDialogOpen(true);
                        }
                      });
                    }}
                  >
                    {t("button.delete_user_acc")}
                  </Button>
                  <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>
                      {t("text.need_to_reauthenticate")}
                    </DialogTitle>
                    <DialogContent>
                      {t("text.sensitive_operation_requires_reauth")}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDialogClose}>
                        {t("button.no")}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setDialogOpen(false);
                          dispatch(signOut());
                        }}
                        autoFocus
                      >
                        {t("menu.signout")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </SettingdDiv>
              </SettingdDiv>
            </SettingdDiv>
          </AccountsettingsDiv>
        </GridRootDashboard>
        <Toaster />
      </RootDivReport>
    </>
  );
}

export default AccountSettingsPage;
