import {
  Box,
  Paper,
  Button,
  TextField,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import logo from "../../original_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { handleResetPassword, getEmail } from "../../store/actions/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const justifyCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const theme = useTheme();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [actionCode, setActionCode] = useState(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.auth.authError);
  const displayEmail = useSelector((state) => state.auth.displayEmail);
  const passwordResetErr = useSelector((state) => state.auth.passwordResetErr);
  const query = useQuery();
  const { t } = useTranslation();

  function handlePasswordFieldChange(e) {
    setPassword(e.target.value);
  }

  function handleConfirmPasswordFieldChange(e) {
    setConfirmPassword(e.target.value);
  }

  function getQueryParams(query) {
    setActionCode(query.get("oobCode"));
  }

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", getQueryParams(query), false);
    return () => {
      document.removeEventListener(
        "DOMContentLoaded",
        getQueryParams(query),
        false
      );
    };
  }, []);

  useEffect(() => {
    if (actionCode !== null) {
      dispatch(getEmail(actionCode));
    }
  }, [actionCode]);

  useEffect(() => {
    if (auth.uid) {
      history.replace("/dashboard");
    }
    if (authError) {
      console.log(authError);
    }
  }, [auth.uid, authError, history]);

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "650px",
          minWidth: "200px",
          margin: "10px auto",
          zIndex: theme.zIndex.drawer,
        }}
      >
        <Paper sx={{ margin: "10px", padding: "20px" }} elevation={10}>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={justifyCenter}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "230px",
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <Typography
                variant="h6"
                sx={justifyCenter}
                style={{ marginBottom: "10px", color: "#3b4240" }}
              >
                {t("text.reset_password")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <Typography
                variant="body1"
                sx={justifyCenter}
                style={{ marginBottom: "10px", color: "#3b4240" }}
              >
                {displayEmail}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Passwort"
                placeholder="Passwort"
                type="password"
                error={passwordResetErr !== null}
                helperText={passwordResetErr != null ? passwordResetErr : null}
                onChange={handlePasswordFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Passwort bestätigen"
                placeholder="Passwort bestätigen"
                type="password"
                error={
                  passwordResetErr !== null || password !== confirmPassword
                }
                onChange={handleConfirmPasswordFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={password === "" || password !== confirmPassword}
                disableElevation={true}
                sx={{
                  textTransform: "none",
                  height: "40px",
                  width: "100%",
                }}
                onClick={() => {
                  dispatch(handleResetPassword(actionCode, password));
                }}
              >
                {t("button.set_new_password")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}

export default ResetPassword;
