import React from "react";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { CircularProgress, Backdrop } from "@mui/material";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <React.Fragment>
      {isLoaded(auth) ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "transparent",
          }}
        >
          <CircularProgress sx={{ color: "#FFFF" }} />
        </Backdrop>
      )}
    </React.Fragment>
  );
}

export default AuthIsLoaded;
