const initState = {
  userPreferences: null,
  uid: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_USER_PREFERENCES_SUCCESS":
      return {
        ...state,
        userPreferences: action.payload,
      };
    case "UPDATE_USER_PREFERENCES_SUCCESS":
      return {
        ...state,
        uid: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
