const initState = {
  chargingStations: null,
  getChargingStationErr: null,
  addedChargingStation: null,
  addedChargingStationError: null,
  lastSettledTransaction: null,
  transactions: null,
  getTransactionsErr: null,
  latestStatus: null,
  remoteStartStatus: null,
  remoteStartErr: null,
  remoteStopStatus: null,
  remoteStopErr: null,
  getLatestInProgressTransaction: null,
  chargingMaxCurrent: null,
  getChargingMaxCurrentErr: null,
  setChargingMaxCurrentStatus: null,
  setChargingMaxCurrentErr: null,
  powerUtilization: null,
  powerUtilizationErr: null,
  getChargingStationSettingsRes: null,
  getChargingStationSettingsErr: null,
  setChargingStationSettingsRes: null,
  setChargingStationSettingsErr: null,
  cards: [],
  timers: [],
  getTimersErr: null,
  getCardsErr: null,
  deleteCardErr: null,
};

const chargingStationReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_CHARGING_STATION_ERROR":
      return {
        ...state,
        chargingStations: [],
        getChargingStationErr: action.payload,
      };
    case "GET_CHARGING_STATION_SUCCESS":
      return {
        ...state,
        chargingStations: action.payload,
      };
    case "ADD_CHARGING_STATION_SUCCESS":
      return {
        ...state,
        addedChargingStation: action.payload,
        addedChargingStationError: null,
      };
    case "APPEND_CHARGING_STATION":
      return {
        ...state,
        chargingStations: action.payload,
      };
    case "DELETE_CHARGING_STATION_SUCCESS":
      return {
        ...state,
        chargingStations: action.payload,
      };
    case "ADD_CHARGING_STATION_ERROR":
      return {
        ...state,
        addedChargingStationError: action.payload,
      };
    case "GET_LAST_SETTLED_TRANSACTION_SUCCESS":
      return {
        ...state,
        lastSettledTransaction: action.payload,
      };
    case "GET_STATUS_SUCCESS":
      return {
        ...state,
        latestStatus: action.payload,
      };
    case "GET_STATUS_ERROR":
      return {
        ...state,
        latestStatus: null,
      };
    case "REMOTE_START_SUCCESS":
      return {
        ...state,
        remoteStartStatus: action.payload,
        remoteStopStatus: null,
      };
    case "REMOTE_STOP_SUCCESS":
      return {
        ...state,
        remoteStopStatus: action.payload,
        remoteStartStatus: null,
      };
    case "GET_LATEST_IN_PROGRESS_TRANSACTION_SUCCESS":
      return {
        ...state,
        getLatestInProgressTransaction: action.payload,
      };
    case "GET_CHARGING_STATION_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        transactions: action.payload,
        getTransactionsErr: null,
      };
    case "GET_CHARGING_STATION_TRANSACTIONS_ERROR":
      return {
        ...state,
        transactions: null,
        getTransactionsErr: action.payload,
      };
    case "GET_CHARGE_RATE_SUCCESS":
      return {
        ...state,
        chargingMaxCurrent: parseInt(action.payload, 10),
        getChargingMaxCurrentErr: null,
      };
    case "GET_CHARGE_RATE_ERROR":
      return {
        ...state,
        chargingMaxCurrent: null,
        getChargingMaxCurrentErr: action.payload,
      };
    case "SET_CHARGE_RATE_SUCCESS":
      return {
        ...state,
        setChargingMaxCurrentStatus: action.payload,
        setChargingMaxCurrentErr: null,
      };
    case "SET_CHARGE_RATE_ERROR":
      return {
        ...state,
        setChargingMaxCurrentStatus: null,
        setChargingMaxCurrentErr: action.payload,
      };
    case "SET_CHARGING_STATION_ERROR__TO_NULL":
      return {
        ...state,
        setChargingMaxCurrentErr: null,
        getChargingMaxCurrentErr: null,
      };
    case "GET_CHARGING_STATION_POWER_UTILIZATION":
      return {
        ...state,
        powerUtilization: action.payload,
        powerUtilizationErr: null,
      };
    case "GET_CHARGING_STATION_POWER_UTILIZATION_ERROR":
      return {
        ...state,
        powerUtilization: null,
        powerUtilizationErr: action.payload,
      };
    case "GET_CHARGING_STATION_SETTINGS":
      return {
        ...state,
        getChargingStationSettingsRes: action.payload,
        getChargingStationSettingsErr: null,
      };
    case "GET_CHARGING_STATION_SETTINGS_ERROR":
      return {
        ...state,
        getChargingStationSettingsRes: null,
        getChargingStationSettingsErr: action.payload,
      };
    case "SET_CHARGING_STATION_SETTINGS":
      return {
        ...state,
        setChargingStationSettingsRes: action.payload,
        setChargingStationSettingsErr: null,
      };
    case "SET_CHARGING_STATION_SETTINGS_ERROR":
      return {
        ...state,
        setChargingStationSettingsRes: null,
        setChargingStationSettingsErr: action.payload,
      };
    case "GET_CARDS":
      return {
        ...state,
        cards: action.payload,
        getCardsErr: null,
      };
    case "GET_CARDS_ERROR":
      return {
        ...state,
        cards: [],
        getCardsErr: action.payload,
      };
    case "DELETE_CARD_SUCCESS":
      return {
        ...state,
        cards: action.payload,
        deleteCardErr: null,
      };
    case "APPEND_CARD":
      return {
        ...state,
        cards: action.payload,
      };
    case "GET_TIMERS":
      console.log(action.payload);
      return {
        ...state,
        timers: action.payload,
        getTimersErr: null,
      };
    case "GET_TIMERS_ERROR":
      return {
        ...state,
        timers: [],
        getTimersErr: action.payload,
      };
    case "DELETE_TIMER_SUCCESS":
      return {
        ...state,
        timers: action.payload,
        deletTimerErr: null,
      };
    case "APPEND_TIMER":
      return {
        ...state,
        timers: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chargingStationReducer;
