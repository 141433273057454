import React from "react";
import { Tooltip, IconButton, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  SettingClasses,
  SettingdDiv,
  IconButtonRoot,
} from "../../logged_in/Settings";
import {
  loadbalancerClasses,
  LoadbalancerDiv,
  RootH3,
  RootP,
} from "../../logged_in/LoadBalancerDetail";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import FilterDramaRoundedIcon from "@mui/icons-material/FilterDramaRounded";

const CustomStatusCard = ({
  chargingStationID,
  alias,
  connected,
  status,
  activePowerImport,
  handleRemoveStationFromLB,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <SettingdDiv
        className={`${SettingClasses.CardBody} ${loadbalancerClasses.loadbalancercard_cardbody}`}
        sx={{
          minHeight: "unset!important",
        }}
      >
        <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
          <LoadbalancerDiv className={loadbalancerClasses.cardheadblock}>
            <RootH3
              className={loadbalancerClasses.cardheadh3}
              sx={{
                fontSize: "24px!important",
                textTransform: "uppercase!important",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "22px!important",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "20px!important",
                },
              }}
            >
              {alias}
            </RootH3>
            <IconButtonRoot
              className={SettingClasses.IconButtonRoot}
              aria-label="remove station from loadbalancer"
              onClick={handleRemoveStationFromLB}
              size="large"
              sx={{
                marginRight: "-8px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: "0px",
                },
              }}
            >
              <DeleteOutlineIcon />
            </IconButtonRoot>
          </LoadbalancerDiv>
          <RootP className={loadbalancerClasses.stationid}>
            {`# ${chargingStationID}`}
          </RootP>
          <LoadbalancerDiv
            className={loadbalancerClasses.statuslogo}
            sx={{ marginLeft: "-5px", marginBottom: "6px" }}
          >
            {connected ? (
              <Tooltip title={t("text.connected_to_the_internet")}>
                <IconButton aria-label="status" size="small">
                  <FilterDramaRoundedIcon sx={{ color: "#298D74" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("text.disconnected_from_the_internet")}>
                <IconButton aria-label="status" size="small">
                  <CloudOffRoundedIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </LoadbalancerDiv>
          <LoadbalancerDiv className={loadbalancerClasses.bottomtext}>
            <LoadbalancerDiv className={loadbalancerClasses.bottomtext_left}>
              <h2>{t(`server_val.${status}`)}</h2>
              <p> Status</p>
            </LoadbalancerDiv>
            <LoadbalancerDiv className={loadbalancerClasses.bottomtext_right}>
              <h2 style={{ textTransform: "uppercase" }}>
                {activePowerImport} KW
              </h2>
              <p>{t("text.charging_power")}</p>
            </LoadbalancerDiv>
          </LoadbalancerDiv>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
};

export default CustomStatusCard;
