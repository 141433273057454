import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.uid ? <Component {...props} /> : <Redirect to="/signin" />;
      }}
    />
  );
}

export default PrivateRoute;
