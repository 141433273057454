import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import auth from "./auth";
import chargingStation from "./chargingStation";
import chargingStationRequestState from "./chargingStationRequestState";
import loadBalancer from "./loadBalancer";
import user from "./user";
import app from "./app";
import pv from "./pv";

const root = combineReducers({
  auth: auth,
  app: app,
  user: user,
  chargingStation: chargingStation,
  loadBalancer: loadBalancer,
  chargingStationRequestState: chargingStationRequestState,
  pv: pv,
  firebase: firebaseReducer,
});

export default root;
