const initState = {
  idToken: null,
  authError: null,
  displayEmail: "",
  sendPasswdResetEmailErr: null,
  passwordResetErr: null,
  verificationCodeErr: null,
  passwordUpdateErr: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNIN_ERROR":
      return {
        ...state,
        authError: action.payload,
      };
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "EMAIL_FROM_ACTION_CODE":
      return {
        ...state,
        displayEmail: action.payload,
      };
    case "PASSWD_RESET_EMAIL_SENT":
      return {
        ...state,
      };
    case "ERR_SENDING_PASSWD_RESET_EMAIL":
      return {
        ...state,
        sendPasswdResetEmailErr: action.payload,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        passwordResetErr: null,
        verificationCodeErr: null,
      };
    case "RESET_PASSWORD_ERR":
      return {
        ...state,
        passwordResetErr: action.payload,
      };
    case "VERIFICATION_CODE_ERROR":
      return {
        ...state,
        verificationCodeErr: action.payload,
      };
    case "PASSWORD_UPDATE_SUCCESSFUL":
      return {
        ...state,
        passwordUpdateErr: null,
      };
    case "PASSWORD_UPDATE_FAILED":
      return {
        ...state,
        passwordUpdateErr: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
