const initState = {
  loadBalancers: [],
  getLoadBalacersErr: null,
};

const loadBalancerReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_LOADBALANCERS_SUCCESS":
      return {
        ...state,
        loadBalancers: action.payload,
        getLoadBalacersErr: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default loadBalancerReducer;
