export const signInWithEmailAndPassword = (credentials) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    var googleProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    googleProvider.addScope("profile");
    googleProvider.addScope("email");
    firebase
      .auth()
      .signInWithRedirect(googleProvider)
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};

export const signinWithApple = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    var appleProvider = new firebase.auth.OAuthProvider("apple.com");
    appleProvider.addScope("email");
    appleProvider.addScope("name");

    firebase
      .auth()
      .signInWithRedirect(appleProvider)
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};
export const signUpWithEmailAndPassword = (credentials) => {
  return (dispatch, getState, getFirebase) => {
    if (credentials.password !== credentials.confirmPassword) {
      return dispatch({
        type: "SIGNIN_ERROR",
        payload: "The two passwords do not match",
      });
    }

    const firebase = getFirebase();
    firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((result) => {
        result.user
          .updateProfile({
            displayName: credentials.name,
          })
          .then(() => {
            dispatch({ type: "SIGNIN_SUCCESS" });
          })
          .catch((err) => {
            dispatch({ type: "SIGNIN_ERROR", payload: err });
          });
      })
      .catch((err) => {
        dispatch({ type: "SIGNIN_ERROR", payload: err });
      });
  };
};

export const sendPasswordResetEmail = (email) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: "PASSWD_RESET_EMAIL_SENT" });
      })
      .catch((err) => {
        dispatch({ type: "ERR_SENDING_PASSWD_RESET_EMAIL", payload: err });
      });
  };
};

export const getEmail = (actionCode) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        dispatch({ type: "EMAIL_FROM_ACTION_CODE", payload: email });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const handleResetPassword = (actionCode, newPassword) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const auth = firebase.auth();
    auth.useDeviceLanguage();
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        auth
          .confirmPasswordReset(actionCode, newPassword)
          .then((res) => {
            // password reset successful, let's sign-in the user directly
            console.log(res);
            dispatch({ type: "RESET_PASSWORD_SUCCESS" });
            dispatch(
              signInWithEmailAndPassword({
                email: email,
                password: newPassword,
              })
            );
          })
          .catch((err) => {
            // Error during confirmation. The code might have expired or the password is too weak.
            dispatch({ type: "RESET_PASSWORD_ERR", payload: err.message });
            console.log(err.message);
          });
      })
      .catch((err) => {
        // Invalid or expired action code. Ask the user to try to reset the password again.
        console.log(err);
        dispatch({ type: "VERIFICATION_CODE_ERROR", payload: err });
      });
  };
};

export const getIdToken = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    var user = firebase.auth().currentUser;
    user
      .getIdToken(true)
      .then((idToken) => {
        dispatch({ type: "ID_TOKEN_RETRIEVED", payload: idToken });
      })
      .catch((err) => {
        dispatch({ type: "ID_TOKEN_RETRIEVE_ERROR", payload: err });
      });
  };
};

export const updatePasswd = (newPassword) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    user
      .updatePassword(newPassword)
      .then(() => {
        dispatch({ type: "PASSWORD_UPDATE_SUCCESSFUL" });
      })
      .catch((err) => {
        dispatch({ type: "PASSWORD_UPDATE_FAILED", payload: err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
