import {
  LAST_SETTLED_TRANSACTION_REQUEST_STATE,
  LATEST_STATUS_REQUEST_STATE,
  GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
  GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  SET_REMOTE_START_REQUEST_STATE,
  SET_REMOTE_STOP_REQUEST_STATE,
  GET_CHARGING_STATION_LATEST_IN_PROGRESS_REQUEST_STATE,
} from "../types/types";

const initState = {
  latestStatusRequestLoading: null,
  lastSettledTransactionRequestLoading: null,
  getChargingStationTransactionsRequestLoading: null,
  getChargingStationSettingsLoading: null,
  setChargingStationSettingsLoading: null,
  remoteStartLoading: null,
  remoteStopLoading: null,
  getChargingStationLatestInProgressTransactionRequestLoading: null,
};

const chargingStationRequestStateReducer = (state = initState, action) => {
  switch (action.type) {
    case LATEST_STATUS_REQUEST_STATE:
      return {
        ...state,
        latestStatusRequestLoading: action.payload,
      };
    case LAST_SETTLED_TRANSACTION_REQUEST_STATE:
      return {
        ...state,
        lastSettledTransactionRequestLoading: action.payload,
      };
    case GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE:
      return {
        ...state,
        getChargingStationTransactionsRequestLoading: action.payload,
      };
    case GET_CHARGING_STATION_SETTINGS_REQUEST_STATE:
      return {
        ...state,
        getChargingStationSettingsLoading: action.payload,
      };
    case SET_CHARGING_STATION_SETTINGS_REQUEST_STATE:
      return {
        ...state,
        setChargingStationSettingsLoading: action.payload,
      };
    case SET_REMOTE_START_REQUEST_STATE:
      return {
        ...state,
        remoteStartLoading: action.payload,
      };
    case SET_REMOTE_STOP_REQUEST_STATE:
      return {
        ...state,
        remoteStopLoading: action.payload,
      };
    case GET_CHARGING_STATION_LATEST_IN_PROGRESS_REQUEST_STATE:
      return {
        ...state,
        getChargingStationLatestInProgressTransactionRequestLoading:
          action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chargingStationRequestStateReducer;
