import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getChargingStationPowerUtilization } from "../../store/actions/chargingStation";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { classesx as classesxReport } from "./Report";
import { getFirebase } from "react-redux-firebase";
import { ChartsSkeleton } from "../common/loading_placeholder/Loading";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
} from "./Report";
import {
  DashboarClasses,
  DashboardDiv,
  GridRoot as GridRootDashboard,
} from "./Dashboard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Line, Bar } from "react-chartjs-2";
import { getChargingStations } from "../../store/actions/chargingStation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import Background from "../common/background/background";
import { SelectedStationContext } from "../contexts/selectedStation";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const PREFIX = "GraphPage";
const GraphClasses = {
  section_1: `${PREFIX}-section_1`,
  section_2: `${PREFIX}-section_2`,
  GridRoot_container_Graph: `${PREFIX}-GridRoot_container`,
  CardRoot: `${PREFIX}-CardRoot`,
  Graph_row: `${PREFIX}-Graph_row`,
  card_header: `${PREFIX}-card_header`,
  Grapg_Wrapper: `${PREFIX}-Grapg_Wrapper`,
};
export const GridRootGraph = styled(Grid)(({ theme }) => ({
  [`&.${GraphClasses.section_1}`]: {
    boxSizing: "border-box",
  },
  [`&.${GraphClasses.section_2}`]: {
    boxSizing: "border-box",
  },
}));

export const CardRoot = styled(Card)(({ theme }) => ({
  [`&.${GraphClasses.CardRoot}`]: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "35px",
    padding: "30px 35px",
    [theme.breakpoints.down("lg")]: {
      padding: "30px 25px",
      borderRadius: "25px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "25px 20px",
      borderRadius: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
      borderRadius: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px",
      borderRadius: "10px",
    },
  },
}));

export const RootH3 = styled("h3")(({ theme }) => ({
  [`&.${GraphClasses.card_header}`]: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.2,
    color: "#B3B3B3",
    textTransform: "uppercase",
    marginBottom: "25px",
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginBottom: "12px",
    },
  },
}));

export const RootDivGraph = styled("div")(({ theme }) => ({
  [`&.${GraphClasses.Grapg_Wrapper}`]: {
    width: "100%",
    height: "430px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      height: "400px",
    },
    [theme.breakpoints.down("md")]: {
      height: "380px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "310px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "250px",
    },
  },
}));

function Graph() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const csCtx = useContext(SelectedStationContext);
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const { t } = useTranslation();
  const [powerData, setPowerData] = useState({ datasets: [] });
  const [costData, setCostData] = useState({ datasets: [] });

  const powerUtilization = useSelector(
    (state) => state.chargingStation.powerUtilization
  );

  const chargingStationIDs = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      chargingStationIDs !== null &&
      chargingStationIDs.length > 0 &&
      csCtx.selectedStation === ""
    ) {
      csCtx.setSelectedStation(chargingStationIDs[0].charging_station_id);
    }
  }, [chargingStationIDs]);

  // -----------end----------------------------------------------------------------------

  var ChartFont = (mdDown, smDown, xsDown) => {
    if (mdDown === true && smDown === false && xsDown === false) {
      return 14;
    } else if (smDown === true && mdDown === true && xsDown === false) {
      return 12;
    } else if (smDown === true && mdDown === true && xsDown === true) {
      return 10;
    } else {
      return 16;
    }
  };
  useEffect(() => {
    if (csCtx.selectedStation !== "") {
      dispatch(getChargingStationPowerUtilization(csCtx.selectedStation));
    }
  }, [csCtx.selectedStation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (powerUtilization !== null && powerUtilization !== undefined) {
      const monthLabels = powerUtilization.map((item) => {
        return item.month;
      });
      const powerUsed = powerUtilization.map((item) => {
        return item.power_consumed;
      });
      const costIncurred = powerUtilization.map((item) => {
        return item.cost;
      });

      const powerData = {
        //labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        labels: monthLabels,
        datasets: [
          {
            // data: [50, 100, 140, 190, 240, 15],
            data: powerUsed,
            label: "kWh",
            fill: "start",
            tension: 0.4,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(25, 0, 0, 300);
              gradient.addColorStop(0, "rgb(41 141 116 / 20%)");
              gradient.addColorStop(1, "rgba(41, 141 ,116,0)");
              return gradient;
            },
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
            pointRadius: 4,
          },
        ],
      };

      const costData = {
        //labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        labels: monthLabels,
        datasets: [
          {
            // data: [15, 25, 30, 43, 53, 8],
            data: costIncurred,
            backgroundColor: [
              theme.palette.primary.main,
              theme.palette.primary.main,
              theme.palette.primary.main,
              theme.palette.primary.main,
              theme.palette.primary.main,
              theme.palette.primary.main,
              theme.palette.primary.main,
            ],
            borderWidth: 0,
            borderRadius: 5,
            barPercentage: 0.37,
            label: "Euro €",
            hoverBackgroundColor: theme.palette.primary.dark,
          },
        ],
      };

      setPowerData(powerData);
      setCostData(costData);
    }
  }, [powerUtilization]);

  const OptionsLine = {
    responsive: true,
    maintainAspectRatio: false,
    transitions: {
      show: {
        animations: {
          x: {
            from: 0,
          },
          y: {
            from: 0,
          },
        },
      },
      hide: {
        animations: {
          x: {
            to: 0,
          },
          y: {
            to: 0,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          barPercentage: 0.5,
          borderColor: "#fff",
        },
        ticks: {
          font: {
            size: ChartFont(mdDown, smDown, xsDown),
            weight: 500,
            color: "red",
            family: "'Roboto', sans-serif",
            style: "normal",
          },
        },
        gridLines: {
          borderDash: [8, 4],
          color: "#348632",
        },
      },
      y: {
        grid: {
          borderColor: "#fff",
          borderDash: [4, 7],
          color: "#bebebe",
          family: " 'Roboto', sans-serif",
          style: "normal",
        },
        ticks: {
          stepSize: 10,
          callback: (label) => `${label} kWh`,
          font: {
            size: ChartFont(mdDown, smDown, xsDown),
            weight: 500,
            color: "red",
            family: "'Roboto', sans-serif",
            style: "normal",
          },
        },
      },
    },
  };
  const OptionsBar = {
    responsive: true,
    maintainAspectRatio: false,
    transitions: {
      show: {
        animations: {
          x: {
            from: 0,
          },
          y: {
            from: 0,
          },
        },
      },
      hide: {
        animations: {
          x: {
            to: 0,
          },
          y: {
            to: 0,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          barPercentage: 0.5,
          borderColor: "#fff",
        },
        angleLines: {
          display: false,
        },
        ticks: {
          font: {
            size: ChartFont(mdDown, smDown, xsDown),
            weight: 500,
            color: "rgba(125, 136, 133, 1)",
            family: "'Roboto', sans-serif",
            style: "normal",
          },
        },
      },
      y: {
        grid: {
          borderColor: "#fff",
          borderDash: [4, 7],
          color: "#bebebe",
        },
        ticks: {
          callback: (label) => `${label} €`,
          font: {
            size: ChartFont(mdDown, smDown, xsDown),
            weight: 500,
            family: " 'Roboto', sans-serif",
            style: "normal",
          },
        },
      },
    },
  };

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${GraphClasses.GridRoot_container_Graph}`}
        >
          <GridRootGraph
            className={GraphClasses.section_1}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <RootDivReport
              className={classesxReport.form_row}
              sx={{
                marginBottom: "30px",
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "20px",
                },
              }}
            >
              <DashboardDiv
                className={DashboarClasses.Input_Wrapper}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    width: "100%!important",
                  },
                }}
              >
                <RootDivReport
                  className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      flexDirection: "column",
                      alignItems: "flex-start!important",
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "0px!important",
                    },
                  }}
                >
                  <Labelh4Report className={classesxReport.labelh4_form}>
                    {`${t("text.charging_station")}`}
                  </Labelh4Report>
                  <RootDivReport className={classesxReport.selct_dropdown}>
                    <FormControlRootReport
                      className={classesxReport.FormControlRoot}
                    >
                      <Select
                        value={csCtx.selectedStation}
                        onChange={(event) =>
                          csCtx.setSelectedStation(event.target.value)
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        {chargingStationIDs !== null &&
                        chargingStationIDs.length > 0
                          ? chargingStationIDs.map((cs) => (
                              <MenuItemRootReport
                                className={classesxReport.MenuItemRoot}
                                key={cs.unqid}
                                value={cs.charging_station_id}
                              >
                                {cs.alias || cs.charging_station_id}
                              </MenuItemRootReport>
                            ))
                          : null}
                      </Select>
                    </FormControlRootReport>
                  </RootDivReport>
                </RootDivReport>
              </DashboardDiv>
            </RootDivReport>
          </GridRootGraph>
          <GridRootGraph
            className={GraphClasses.section_2}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <DashboardDiv
              className={`${DashboarClasses.cards_row} ${GraphClasses.Graph_row}`}
            >
              <DashboardDiv
                className={`${DashboarClasses.Col_6} ${DashboarClasses.MB_30px}`}
              >
                <CardRoot className={GraphClasses.CardRoot}>
                  <RootH3 className={GraphClasses.card_header}>
                    {t("text.power_consumption")}
                  </RootH3>

                  {powerUtilization !== null &&
                  powerUtilization !== undefined ? (
                    <>
                      <RootDivGraph className={GraphClasses.Grapg_Wrapper}>
                        <Line
                          data={powerData}
                          id={"LineChart"}
                          width="100%"
                          height="100%"
                          options={OptionsLine}
                        />
                      </RootDivGraph>
                    </>
                  ) : (
                    <ChartsSkeleton />
                  )}
                </CardRoot>
              </DashboardDiv>
              <DashboardDiv
                className={`${DashboarClasses.Col_6} ${DashboarClasses.MB_30px}`}
              >
                <CardRoot className={GraphClasses.CardRoot}>
                  <RootH3 className={GraphClasses.card_header}>
                    {t("text.electricity_costs")}
                  </RootH3>

                  {powerUtilization !== null &&
                  powerUtilization !== undefined ? (
                    <>
                      <RootDivGraph className={GraphClasses.Grapg_Wrapper}>
                        <Bar
                          data={costData}
                          id={"BarChart"}
                          width="100%"
                          height="100%"
                          options={OptionsBar}
                        />
                      </RootDivGraph>
                    </>
                  ) : (
                    <ChartsSkeleton />
                  )}
                </CardRoot>
              </DashboardDiv>
            </DashboardDiv>
          </GridRootGraph>
        </GridRootDashboard>
      </RootDivReport>
    </>
  );
}

export default Graph;
