import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Slider,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  useTheme,
  Box,
  Skeleton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { getFirebase } from "react-redux-firebase";
import {
  getChargingStations,
  getCards,
  saveCards,
  deleteRFIDCard,
  appendCard,
  saveChargingTimers,
  getChargingTimers,
  deleteChargingTimer,
  appendTimer,
} from "../../store/actions/chargingStation";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import AllowIcon from "@mui/icons-material/Check";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deletePowerRate,
  getChargingStation,
  getPowerRates,
  getCards as getCardsWithRQ,
  insertOrUpdatePowerRates,
  getChargingStationSettings,
  setChargingStationSettings,
  changeCurrent,
  getCurrent,
} from "../../lib/api";
import { classesx as classesxReport } from "./Report";
import { styled } from "@mui/material/styles";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
} from "./Report";
import {
  DashboarClasses,
  DashboardDiv,
  GridRoot as GridRootDashboard,
} from "./Dashboard";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Background from "../common/background/background";
import { SelectedStationContext } from "../contexts/selectedStation";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { LoadingButton } from "@mui/lab";
import QueueIcon from "@mui/icons-material/Queue";
import moment from "moment";
import shortid from "shortid";

import NumberParser from "../../lib/util_classes/number_parser";

export const PREFIX = "settingpage";
export const SettingClasses = {
  GridRoot_container_settings: `${PREFIX}-GridRoot_container`,
  section_1: `${PREFIX}-section_1`,
  section_2: `${PREFIX}-section_2`,
  cards_row_First: `${PREFIX}-cards_row_First`,
  CardBody: `${PREFIX}-CardBody`,
  cards_row_First_item1: `${PREFIX}-cards_row_First_item1`,
  cards_row_First_item2: `${PREFIX}-cards_row_First_item2`,
  CardTop_content: `${PREFIX}-cardTop_content`,
  setting_card_h3: `${PREFIX}-setting_card_h3`,
  setting_card_subheader: `${PREFIX}-setting_card_subheader`,
  timer_block: `${PREFIX}-timer_block`,
  timer_block_right: `${PREFIX}-timer_block_right`,
  timer_block_Left: `${PREFIX}-timer_block_Left`,
  timer_input: `${PREFIX}-timer_input`,
  TextFieldRoot: `${PREFIX}-TextFieldRoot`,
  IconButtonRoot: `${PREFIX}-IconButtonRoot`,
  timer_block_parent: `${PREFIX}-timer_block_parent`,
  Add_timer: `${PREFIX}-Add_timer`,
  Add_timer_text: `${PREFIX}-Add_timer_text`,
  card_btn_block: `${PREFIX}-card_btn_block`,
  ButtonRoot: `${PREFIX}-ButtonRoot`,
  Left_text_block: `${PREFIX}-Left_text_block`,
  Left_text_wrapper: `${PREFIX}-Left_text_wrapper`,
  Slider_block: `${PREFIX}-Slider_block`,
  Slider_block_text: `${PREFIX}-Slider_block_text`,
  cost_edit_block: `${PREFIX}-cost_edit_block`,
  cards_row_second: `${PREFIX}-cards_row_second`,
  cards_row_second_item1: `${PREFIX}-cards_row_second_item1`,
  title_wrapper_authorized_cards: `${PREFIX}-title_wrapper_authorized_cards`,
  rifd_cards_block: `${PREFIX}-rifd_cards_block`,
  add_card_block: `${PREFIX}-add_card_block`,
  rifd_cards: `${PREFIX}-rifd_cards`,
};

export const MenuItemRoot = styled(MenuItem)(({ theme }) => ({
  [`&.${DashboarClasses.MenuItemRoot}`]: {
    lineHeight: "normal",
    fontWeight: 500,
    padding: "10px 5px 10px 10px",
    minHeight: "unset",
    [theme.breakpoints.down("xl")]: {
      padding: "8px 5px 8px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px 8px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

export const GridRootsetting = styled(Grid)(({ theme }) => ({
  [`&.${SettingClasses.section_1}`]: {
    boxSizing: "border-box",
  },
  [`&.${SettingClasses.section_1}`]: {
    boxSizing: "border-box",
  },
}));

export const SettingdDiv = styled("div")(({ theme }) => ({
  [`&.${SettingClasses.CardBody}`]: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    background: "#FFFFFF",
    boxShadow: " 0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "35px",
    minHeight: "330px",
    padding: "30px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      padding: "25px 25px",
      borderRadius: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
      borderRadius: "20px",
      minHeight: "270px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px 15px",
      borderRadius: "15px",
      minHeight: "230px",
    },
  },
  [`&.${SettingClasses.timer_block}`]: {
    paddingTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`&.${SettingClasses.Left_text_block} , &.${SettingClasses.cost_edit_block} , &.${SettingClasses.add_card_block} `]:
    {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  [`&.${SettingClasses.Left_text_block}`]: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  [`&.${SettingClasses.add_card_block}`]: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  [`&.${SettingClasses.Left_text_wrapper}`]: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [`&.${SettingClasses.cost_edit_block}`]: {
    marginTop: "45px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "35px",
    },
  },
  [`&.${SettingClasses.timer_block_parent}`]: {
    marginTop: "10px",
    maxHeight: "115px",
    overflow: "visible auto",
    paddingRight: "5px",
  },
  [`&.${SettingClasses.timer_block_parent}::-webkit-scrollbar`]: {
    width: "3px",
    height: "3px",
  },
  [`&.${SettingClasses.timer_block_parent}::-webkit-scrollbar-track`]: {
    boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
  },
  [`&.${SettingClasses.timer_block_parent}::-webkit-scrollbar-thumb`]: {
    backgroundColor: "#2B8D75",
    borderRadius: "10px",
  },
  [`&.${SettingClasses.cards_row_First_item1}`]: {
    [theme.breakpoints.down("xs")]: {
      [`& .settingpage-timer_block_Left .settingpage-TextFieldRoot .MuiInputBase-input`]:
        {
          padding: "5px 5px 5px 10px",
          fontSize: "13px",
        },
    },
  },

  [`&.${SettingClasses.timer_block_Left}`]: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% - 40px)",
    },
  },
  [`&.${SettingClasses.Add_timer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  [`&.${SettingClasses.card_btn_block}`]: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
  },
  [`&.${SettingClasses.CardTop_content}`]: {
    width: "100%",
  },
  [`&.${SettingClasses.Slider_block}`]: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      [`& .MuiSlider-root`]: {
        marginBottom: "20px",
      },
    },
  },
  [`&.${SettingClasses.cards_row_First_item2}`]: {
    [`& .MuiSelect-select.MuiSelect-outlined`]: {
      fontSize: "16px",
      padding: "16px 14px 16px 25px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "12px 15px 12px 20px",
      },
    },
  },
  [`&.${SettingClasses.cards_row_second_item1}`]: {
    minHeight: "510px",
    padding: "30px 35px",
    [theme.breakpoints.down("lg")]: {
      padding: "25px 25px",
      borderRadius: "25px",
      minHeight: "330px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
      borderRadius: "20px",
      minHeight: "270px",
      [`& .settingpage-rifd_cards .test_card_left .MuiFormControl-root`]: {
        width: "100%",
      },
      [`& .settingpage-rifd_cards .test_card_left .MuiFormControl-root:last-child`]:
        {
          marginTop: "15px",
        },
    },
    [`& .settingpage-rifd_cards_block .settingpage-rifd_cards:last-child`]: {
      marginBottom: "0px!important",
    },
  },
  [`&.${SettingClasses.title_wrapper_authorized_cards}`]: {
    paddingBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "18px",
    },
  },
}));

export const ULRoot = styled("ul")(({ theme }) => ({
  [`&.${SettingClasses.rifd_cards_block}`]: {
    width: "100%",
  },
}));
export const LIRoot = styled("li")(({ theme }) => ({
  [`&.${SettingClasses.rifd_cards}`]: {
    width: "100%",
    backgroundColor: "rgba(43, 141, 117, 0.05)",
    borderRadius: "10px",
    minHeight: "122px",
    padding: "25px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 20px",
      marginBottom: "15px",
      minHeight: "unset",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px",
      marginBottom: "10px",
    },
    [`& .test_card_left`]: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
    [`& .test_card_right`]: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        marginTop: "20px",
      },
    },
    [`& .test_card_right .MuiSelect-select.MuiSelect-outlined`]: {
      padding: "16px",
      [theme.breakpoints.down("sm")]: {
        padding: "12px 14px",
      },
    },
    [`& .settingpage-TextFieldRoot .MuiInputBase-input`]: {
      backgroundColor: "#fff",
      maxWidth: "200px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
  },
}));

export const RootH3 = styled("h3")(({ theme }) => ({
  [`&.${SettingClasses.setting_card_h3}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "1.66",
    textTransform: "uppercase",
    color: "#B3B3B3",
    marginBottom: "6px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      marginBottom: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      marginBottom: "0px",
    },
  },
}));
export const RootP = styled("p")(({ theme }) => ({
  [`&.${SettingClasses.setting_card_subheader}`]: {
    fontSize: "14px",
    lineHeight: "1.42",
    color: " #646464",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  [`&.${SettingClasses.Add_timer_text}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    textTransform: "uppercase",
    color: "#263238",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  [`&.${SettingClasses.Slider_block_text}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "1.66",
    textAlign: "center",
    color: "#263238",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginBottom: "18px",
    },
  },
}));

export const TextFieldRoot = styled(TextField)(({ theme }) => ({
  [`&.${SettingClasses.TextFieldRoot}`]: {
    [`& .MuiInputBase-input`]: {
      padding: "5px 10px 5px 15px",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: 1.5,
      color: "#646464",
      cursor: "pointer",
      height: "unset",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    [`& .MuiInputBase-root`]: {
      backgroundColor: "rgba(41, 141, 116, 0.06)",
      borderRadius: "20px",
    },
    [`& .MuiFormLabel-root`]: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    [`& .MuiOutlinedInput-notchedOutline`]: {
      borderColor: theme.palette.primary.main,
    },
    [`&:hover .MuiOutlinedInput-notchedOutline`]: {
      borderColor: "#646464",
    },
    [`&  .MuiFormLabel-root`]: {
      top: "-6px",
      [theme.breakpoints.down("sm")]: {
        top: "-7px",
      },
    },
    [`&  .MuiFormLabel-root.Mui-focused`]: {
      top: "0px",
    },
    [`& .MuiFormLabel-root.Mui-disabled.MuiFormLabel-filled`]: {
      top: "0px",
    },
    [`& .MuiFormLabel-root.Mui-disabled`]: {
      top: "-6px",
      [theme.breakpoints.down("sm")]: {
        top: "-7px",
      },
    },
    [`& .MuiFormLabel-root.MuiFormLabel-filled`]: {
      top: "0px",
    },
  },

  [`&.${SettingClasses.TextFieldRoot}:first-of-type`]: {
    marginRight: "28px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "unset",
    },
  },
}));

export const IconButtonRoot = styled(IconButton)(({ theme }) => ({
  [`&.${SettingClasses.IconButtonRoot}`]: {
    color: theme.palette.primary.main,
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      [`& .MuiSvgIcon-root`]: {
        fontSize: "1.3rem",
      },
    },

    [`&:hover`]: {
      backgroundColor: "#e31e4d",
      color: "#fff",
    },
    [`&:hover svg path`]: {
      fill: "#fff",
    },
  },
}));

export const ButtonRoot = styled(LoadingButton)(({ theme }) => ({
  [`&.${SettingClasses.ButtonRoot}`]: {
    padding: "10px 27.5px",
    fontWeight: 400,
    fontSize: "20px",
    borderRadius: "35px",
    textTransform: "capitalize",
    lineHeight: "normal",
    boxShadow: "unset",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      padding: "8px 20px",
    },
  },
}));

export const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  marginBottom: "37px",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.66,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textAlign: "center",
    background: "unset",
    padding: 0,
    width: 33,
    height: 33,
    borderRadius: "50% 50% 50% 0",
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

function CurrentSettings({ chargingStation }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [maxAmpMark, setMaxAmpMark] = useState(16);
  const chargingMaxCurrent = useSelector(
    (state) => state.chargingStation.chargingMaxCurrent
  );
  const getChargingMaxCurrentErr = useSelector(
    (state) => state.chargingStation.getChargingMaxCurrentErr
  );
  const setChargingMaxCurrentErr = useSelector(
    (state) => state.chargingStation.setChargingMaxCurrentErr
  );

  const getCurrentQuery = useQuery({
    queryKey: ["get_current", chargingStation],
    queryFn: getCurrent,
    enabled: chargingStation !== "",
  });

  const changeCurrentMutation = useMutation({
    mutationKey: ["change_current", chargingStation],
    mutationFn: changeCurrent,
  });

  const cs = useSelector((state) => {
    const stations = state.chargingStation.chargingStations;
    if (stations !== null) {
      const filteredStations = stations.filter(
        (c) => c.charging_station_id === chargingStation
      );
      if (filteredStations.length > 0) {
        return filteredStations[0];
      }
    }
    return null;
  });

  const chargingStationDetails = useQuery(
    ["cs", chargingStation],
    () => getChargingStation(chargingStation),
    { enabled: chargingStation !== "" }
  );

  let THREE_PHASE_CONVERSION_CONST = 0.6875;
  const [conversionConst, setConversionConst] = useState(
    THREE_PHASE_CONVERSION_CONST
  );
  const [amp, setAmp] = useState(16.0);
  const [kW, setKW] = useState(amp * conversionConst);

  function handleSliderChange(event, newValue) {
    setKW(parseInt(newValue) * conversionConst);
    setAmp(parseInt(newValue));
  }

  function handleReduceCurrentSlider() {
    var newSliderValue;

    setAmp((prevAmpValue) => {
      newSliderValue = prevAmpValue - 1;
      newSliderValue = newSliderValue < 6 ? 6 : newSliderValue;
      return newSliderValue;
    });
  }

  function handleIncreaseCurrentSlider() {
    var newSliderValue;

    setAmp((prevAmpValue) => {
      newSliderValue = prevAmpValue + 1;
      newSliderValue =
        newSliderValue > maxAmpMark ? maxAmpMark : newSliderValue;
      return newSliderValue;
    });
  }

  const marks = [
    {
      value: 6,
      label: "6 A",
    },
    {
      value: maxAmpMark,
      label: `${maxAmpMark} A`,
    },
  ];

  function valueFormat(value) {
    return `${value} A`;
  }

  const changeCurrentToast = (status) => {
    if (status === 202) {
      return toast.success(t("text.change_current_accepted"));
    } else if (status === 200) {
      return toast.error(t("error.change_current_rejected"));
    }
  };

  useEffect(() => {
    if (!changeCurrentMutation.isLoading) {
      if (
        changeCurrentMutation.data !== undefined &&
        changeCurrentMutation.data.status !== undefined
      )
        changeCurrentToast(changeCurrentMutation.data.status);
    }
  }, [changeCurrentMutation.isLoading]);

  useEffect(() => {
    if (!getCurrentQuery.isLoading && getCurrentQuery.data !== undefined) {
      setAmp(parseInt(getCurrentQuery.data.data.value));
      setKW(parseInt(getCurrentQuery.data.data.value) * conversionConst);
    }
  }, [getCurrentQuery.isFetched]);

  useEffect(() => {
    if (cs !== null && cs !== undefined) {
      if (cs.max_charging_power_in_kw === 22) {
        setMaxAmpMark(32);
      } else if (cs.max_charging_power_in_kw === 11) {
        setMaxAmpMark(16);
      }
    }
  }, [cs]);

  return (
    <>
      <SettingdDiv
        className={`${SettingClasses.CardBody} ${SettingClasses.cards_row_First_item2}`}
      >
        <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
          <SettingdDiv className={`${SettingClasses.Left_text_block}`}>
            <SettingdDiv className={`${SettingClasses.Left_text_wrapper}`}>
              <RootH3 className={`${SettingClasses.setting_card_h3}`}>
                {t("text.current_settings")}
              </RootH3>
              <RootP className={`${SettingClasses.setting_card_subheader}`}>
                {t("text.limit_charging_current")}
              </RootP>
            </SettingdDiv>
          </SettingdDiv>
          <SettingdDiv
            className={`${SettingClasses.Slider_block}`}
            sx={{
              marginTop: "0px!important",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <IconButton
              sx={{ marginRight: "10px" }}
              onClick={handleReduceCurrentSlider}
            >
              <RemoveCircleOutline />
            </IconButton>
            <PrettoSlider
              aria-label="pretto slider"
              valueLabelDisplay="on"
              defaultValue={16}
              steps={16}
              min={6}
              max={maxAmpMark}
              marks={marks}
              value={typeof amp === "number" ? amp : 16}
              onChange={handleSliderChange}
              aria-labelledby="current-slider"
              valueLabelFormat={valueFormat}
            />
            <IconButton
              sx={{ marginLeft: "10px" }}
              onClick={handleIncreaseCurrentSlider}
            >
              <AddCircleOutline />
            </IconButton>
          </SettingdDiv>
          <RootP className={`${SettingClasses.Slider_block_text}`}>
            {amp} A / {kW.toFixed(2)} kW
          </RootP>
        </SettingdDiv>
        <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
          <ButtonRoot
            className={`${SettingClasses.ButtonRoot}`}
            color="primary"
            variant="contained"
            loading={changeCurrentMutation.isLoading}
            onClick={() => {
              changeCurrentMutation.mutate({
                chargingStationID: chargingStation,
                chargingCurrent: `${amp}`,
              });
            }}
          >
            {t("button.save")}
          </ButtonRoot>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
}

function PowerRates({ chargingStation }) {
  const { t } = useTranslation();
  const powerRates = useQuery({
    queryKey: ["power_rate", chargingStation],
    queryFn: getPowerRates,
    enabled: chargingStation !== undefined && chargingStation !== "",
  });

  const cardsQuery = useQuery({
    queryKey: ["cards", chargingStation],
    queryFn: getCardsWithRQ,
    enabled: chargingStation !== undefined && chargingStation !== "",
  });

  if (powerRates.isLoading || cardsQuery.isLoading)
    return (
      <Skeleton
        sx={{
          width: "85%",
          height: "50px",
          alignSelf: "flex-start",
        }}
      />
    );

  if (powerRates.isError)
    return (
      <Box
        sx={{
          margin: "10px 0",
          alignSelf: "flex-start",
        }}
      >
        {t("error.error_fetching_power_rates")}
      </Box>
    );

  if (powerRates.data.data == "" && powerRates.data.status === 204) {
    return (
      <Box
        sx={{
          margin: "10px 0",
          alignSelf: "flex-start",
        }}
      >
        {t("text.no_power_rates_found")}
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.up("lg")]: {
          alignSelf: "flex-start",
        },
        gap: "5px",
      })}
    >
      {powerRates.data.data !== "" &&
        [...powerRates.data.data.power_rates].map((pr) => {
          pr.id = shortid.generate();
          return (
            <PowerRatesRow
              key={pr.id}
              localID={pr.id}
              uid={pr.uid}
              idtag={pr.idtag}
              alias={pr.alias}
              allIdtags={cardsQuery.data.data}
              cost={pr.cost_per_kwh}
              validFrom={pr.valid_from}
              validTo={pr.valid_to}
              chargingStation={chargingStation}
              isLocal={pr._local}
              isEdited={pr._edited}
            />
          );
        })}
    </Box>
  );
}

function PowerRatesRow({
  localID,
  uid,
  idtag,
  allIdtags,
  cost,
  validFrom,
  validTo,
  chargingStation,
  isLocal,
  isEdited,
}) {
  const { t, i18n } = useTranslation();
  const [dateRange, setDateRange] = useState([validFrom, validTo]);

  const [_idtag, setIdTag] = useState(idtag || "");
  const [_cost, setCost] = useState(
    cost !== null && cost !== undefined
      ? new Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 4,
        }).format(cost)
      : ""
  );

  const [edited, setEdited] = useState(isEdited || false);

  const queryClient = useQueryClient();

  const deletePowerRateMutation = useMutation({
    mutationKey: ["power_rate", chargingStation],
    mutationFn: deletePowerRate,
    onMutate: async (variables) => {
      // cancel current queries for the power_rates
      await queryClient.cancelQueries({
        queryKey: ["power_rate", chargingStation],
      });

      // get oldData with original powerRates
      const oldData = queryClient.getQueryData(["power_rate", chargingStation]);

      // remove power_rate optimistically
      queryClient.setQueryData(["power_rate", chargingStation], (old) => {
        var prAfterOptimisticDelete = old.data.power_rates.filter(
          (pr) => pr.uid !== variables.uid
        );

        const newData = {
          ...old,
          data: { ...old.data, power_rates: prAfterOptimisticDelete },
        };

        return newData;
      });

      return oldData;
    },
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ["power_rate", chargingStation],
        (oldDataWithOptimisticUpdates) => {
          return context;
        }
      );
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ["power_rate", chargingStation],
      });
    },
  });

  return (
    <Box
      sx={(theme) => ({
        width: "800px",
        margin: "10px 0px",
        padding: "15px",
        display: "flex",
        backgroundColor:
          isLocal || edited ? "rgba(43, 141, 117, 0.05)!important" : "inherit",
        borderRadius: "12px",
        flexDirection: "row",
        gap: "10px",
        border: "1px solid rgba(211, 211, 211, 0.7)!important",
        [theme.breakpoints.down("lg")]: {
          width: "300px",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "flex-start",
        },
        justifyContent: "space-around",
      })}
    >
      <FormControl sx={{ width: "170px" }} required>
        <InputLabel id="demo-simple-select-standard-label">
          {t("text.idtag")}
        </InputLabel>
        <Select
          required
          value={_idtag}
          label={t("text.idtag")}
          placeholder={t("text.idtag")}
          sx={(theme) => ({
            padding: "0px 10px 0px 0px",
            [theme.breakpoints.down("lg")]: { width: "270px" },
          })}
          onChange={async (event) => {
            setEdited(true);
            setIdTag(event.target.value);
            // check if PowerRatesRow is being used to render existing PowerRates data, hence havig a valid UID
            // or just a placeholder for adding new PowerRate.
            if (uid) {
              await queryClient.cancelQueries({
                queryKey: ["power_rate", chargingStation],
              });

              queryClient.setQueryData(
                ["power_rate", chargingStation],
                (old) => {
                  const newPowerRates = old.data.power_rates.map((pr) => {
                    if (pr.uid === uid) {
                      pr.idtag = event.target.value;
                      pr._edited = true;
                    }
                    return pr;
                  });

                  const newData = {
                    ...old,
                    data: { ...old.data, power_rates: newPowerRates },
                  };

                  return newData;
                }
              );
            }
          }}
        >
          {allIdtags &&
            allIdtags.map((id) => (
              <MenuItem key={id.idtag} value={id.idtag}>
                {id.alias}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: "auto" }} required>
        <TextField
          required
          value={_cost}
          label="€ / kWh"
          placeholder="0.29"
          onChange={async (e) => {
            setEdited(true);
            setCost(e.target.value);
            // check if PowerRatesRow is being used to render existing PowerRates data, hence havig a valid UID
            // or just a placeholder for adding new PowerRate.
            if (uid) {
              await queryClient.cancelQueries({
                queryKey: ["power_rate", chargingStation],
              });

              queryClient.setQueryData(
                ["power_rate", chargingStation],
                (old) => {
                  const newPowerRates = old.data.power_rates.map((pr) => {
                    if (pr.uid === uid) {
                      pr.cost_per_kwh = new NumberParser(i18n.language).parse(
                        e.target.value
                      );
                      pr._edited = true;
                    }
                    return pr;
                  });

                  const newData = {
                    ...old,
                    data: { ...old.data, power_rates: newPowerRates },
                  };

                  return newData;
                }
              );
            }
          }}
        />
        <FormHelperText>
          {t("text.cost_for_selected_idtag_and_duration")}
        </FormHelperText>
      </FormControl>
      <DateRangePicker
        required
        clearAriaLabel="Clear value"
        calendarAriaLabel="Toggle calendar"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        yearAriaLabel="Year"
        nativeInputAriaLabel="Date"
        locale={i18n.language}
        value={dateRange}
        onChange={async (value) => {
          setDateRange(value);
          setEdited(true);
          var _validFrom = moment().format(moment.defaultFormat);
          var _validTo = moment().add("M").format(moment.defaultFormat);

          if (value) {
            const validFromD = new Date(Date.parse(value[0]));
            const validToD = new Date(Date.parse(value[1]));
            _validFrom = moment(validFromD).format(moment.defaultFormat);
            _validTo = moment(validToD).format(moment.defaultFormat);
          }

          // check if PowerRatesRow is being used to render existing PowerRates data, hence havig a valid UID
          // or just a placeholder for adding new PowerRate.
          if (uid) {
            await queryClient.cancelQueries({
              queryKey: ["power_rate", chargingStation],
            });

            queryClient.setQueryData(["power_rate", chargingStation], (old) => {
              const newPowerRates = old.data.power_rates.map((pr) => {
                if (pr.uid === uid) {
                  pr.valid_from = _validFrom;
                  pr.valid_to = _validTo;
                  pr._edited = true;
                }
                return pr;
              });
              const newData = {
                ...old,
                data: { ...old.data, power_rates: newPowerRates },
              };
              return newData;
            });
          }
        }}
      />
      {uid !== undefined ? (
        <IconButton
          onClick={async () => {
            if (isLocal) {
              await queryClient.cancelQueries({
                queryKey: ["power_rate", chargingStation],
              });

              await queryClient.setQueryData(
                ["power_rate", chargingStation],
                (old) => {
                  var prAfterOptimisticDelete = old.data.power_rates.filter(
                    (pr) => pr.id !== localID
                  );

                  const newData = {
                    ...old,
                    data: { ...old.data, power_rates: prAfterOptimisticDelete },
                  };
                  return newData;
                }
              );
            } else {
              deletePowerRateMutation.mutate({ uid: uid });
            }
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      ) : (
        <IconButton
          disabled={
            _idtag === "" ||
            _idtag === undefined ||
            _cost === "" ||
            _cost === undefined ||
            dateRange === null ||
            dateRange.toString() === [undefined, undefined].toString()
          }
          onClick={async () => {
            const validFromD = new Date(Date.parse(dateRange[0]));
            const validToD = new Date(Date.parse(dateRange[1]));
            const _validFrom = moment(validFromD).format(moment.defaultFormat);
            const _validTo = moment(validToD).format(moment.defaultFormat);
            await queryClient.cancelQueries({
              queryKey: ["power_rate", chargingStation],
            });
            await queryClient.setQueryData(
              ["power_rate", chargingStation],
              (old) => {
                const newPr = {
                  uid: "",
                  idtag: _idtag,
                  cost_per_kwh: new NumberParser(i18n.language).parse(_cost),
                  valid_from: _validFrom,
                  valid_to: _validTo,
                  _local: true,
                };

                if (old.data !== "") {
                  return {
                    ...old,
                    data: {
                      ...old.data,
                      power_rates: [...old.data.power_rates, newPr],
                    },
                  };
                } else {
                  return {
                    ...old,
                    data: {
                      power_rates: [newPr],
                    },
                  };
                }
              }
            );
            setIdTag("");
            setCost("");
            setDateRange(null);
            setEdited(false);
          }}
        >
          <QueueIcon />
        </IconButton>
      )}
    </Box>
  );
}

function AddPowerRate({ chargingStation }) {
  const cardsQuery = useQuery({
    queryKey: ["cards", chargingStation],
    queryFn: getCardsWithRQ,
    enabled: chargingStation !== undefined && chargingStation !== "",
  });

  return (
    <Box
      sx={(theme) => ({
        marginTop: "10px",
        [theme.breakpoints.up("lg")]: {
          alignSelf: "flex-start",
        },
      })}
    >
      {cardsQuery.isLoading ? null : (
        <PowerRatesRow
          chargingStation={chargingStation}
          allIdtags={cardsQuery.data.data}
          isEdited={false}
        />
      )}
    </Box>
  );
}

function CurrencySettings({ chargingStation }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [cost, setCost] = useState(
    new Intl.NumberFormat(i18n.language).format(0.42)
  );

  const queryClient = useQueryClient();

  const getSettings = useQuery({
    queryKey: ["settings", chargingStation],
    queryFn: getChargingStationSettings,
    enabled: chargingStation !== undefined && chargingStation !== "",
  });

  const setSettings = useMutation({
    mutationKey: ["settings", chargingStation],
    mutationFn: setChargingStationSettings,
  });

  const insertOrUpdateCosts = useMutation({
    mutationKey: ["power_rate", chargingStation],
    mutationFn: insertOrUpdatePowerRates,
    onMutate: async (variables) => {
      // cancel current queries for the power_rates
      await queryClient.cancelQueries({
        queryKey: ["power_rate", chargingStation],
      });

      // get powerRates
      const oldData = queryClient.getQueryData(["power_rate", chargingStation]);

      return oldData;
    },
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ["power_rate", chargingStation],
        (oldDataWithOptimisticUpdates) => {
          return context;
        }
      );
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ["power_rate", chargingStation],
      });
    },
  });

  const savePowerRatesToast = (statusCode) => {
    if (statusCode === 400) return toast.error(t("error.overlap_time_range"));
    if (statusCode === 500) return toast.error(t("error.something_went_wrong"));
    else return toast.success(t("text.power_rates_saved"));
  };

  useEffect(() => {
    if (!getSettings.isLoading) {
      setCost(
        new Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 4,
        }).format(getSettings.data.data.cost_per_kwh)
      );
    }
  }, [getSettings.isLoading]);

  useEffect(() => {
    if (
      insertOrUpdateCosts.error !== undefined &&
      insertOrUpdateCosts.error !== null
    ) {
      savePowerRatesToast(insertOrUpdateCosts.error.response.status);
    } else if (insertOrUpdateCosts.isSuccess) {
      savePowerRatesToast(insertOrUpdateCosts.data.statusCode);
    }
  }, [insertOrUpdateCosts.isLoading]);

  return (
    <>
      <SettingdDiv
        className={`${SettingClasses.CardBody} ${SettingClasses.cards_row_First_item2}`}
      >
        <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
          <RootH3 className={`${SettingClasses.setting_card_h3}`}>
            {t("text.costs")}
          </RootH3>
          <RootP className={`${SettingClasses.setting_card_subheader}`}>
            {t("text.costs_subheader")}
          </RootP>
        </SettingdDiv>

        {getSettings.isLoading ? (
          <Skeleton
            sx={{
              width: "85%",
              height: "50px",
              alignSelf: "flex-start",
            }}
          />
        ) : (
          <TextField
            required
            label={t("text.default_rate")}
            sx={{
              width: "300px",
              margin: "20px 0px",
              [theme.breakpoints.up("lg")]: {
                width: "800px",
                alignSelf: "flex-start",
              },
            }}
            value={cost}
            onChange={(e) => {
              setCost(e.target.value);
            }}
          />
        )}

        <PowerRates chargingStation={chargingStation} />

        <AddPowerRate chargingStation={chargingStation} />

        <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
          <ButtonRoot
            className={`${SettingClasses.ButtonRoot}`}
            color="primary"
            variant="contained"
            onClick={() => {
              const oldData = queryClient.getQueryData([
                "power_rate",
                chargingStation,
              ]);

              var editedPowerRates = [];
              if (oldData.data.power_rates !== undefined) {
                editedPowerRates = oldData.data.power_rates
                  .filter((pr) => pr._local === true || pr._edited === true)
                  .map((pr) => {
                    const { _local, ...newPr } = pr;
                    return newPr;
                  });
              }

              if (editedPowerRates.length > 0) {
                const insertOrUpdateCostsReq = {
                  charging_station_id: chargingStation,
                  power_rates: editedPowerRates,
                };
                insertOrUpdateCosts.mutate({ req: insertOrUpdateCostsReq });
              }

              const setSettingsReq = {
                cost_per_kwh: new NumberParser(i18n.language).parse(cost),
              };
              setSettings.mutate({
                chargingStation: chargingStation,
                req: setSettingsReq,
              });
            }}
            disabled={false}
            loading={insertOrUpdateCosts.isLoading || setSettings.isLoading}
          >
            {t("button.save")}
          </ButtonRoot>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
}

function RFIDCard({
  alias,
  rfid,
  status,
  handleAliasChange,
  handleRFIDChange,
  handleAccessChange,
  handleDelete,
  disableInputs,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditButton = () => {
    setDisable(!disable);
  };

  useEffect(() => {
    if (alias === "" && rfid === "") {
      setDisable(false);
    }
    if (disableInputs === true) {
      setDisable(true);
    }
  }, [alias, rfid, status, disableInputs]);

  return (
    <>
      <LIRoot className={SettingClasses.rifd_cards}>
        <div className="test_card_left">
          <TextFieldRoot
            variant="outlined"
            placeholder={t("text.user_alias")}
            label="Benutzer / Alias"
            defaultValue={alias}
            disabled={disable}
            onChange={handleAliasChange}
            className={SettingClasses.TextFieldRoot}
            sx={{ marginRight: "30px" }}
            type="Alias"
            id="Alias"
          />
          <TextFieldRoot
            className={SettingClasses.TextFieldRoot}
            type="card_id"
            id="card_id"
            variant="outlined"
            placeholder={t("text.card_id")}
            label={t("text.card_id")}
            defaultValue={rfid}
            disabled={disable}
            onChange={handleRFIDChange}
          />
        </div>

        <div className="test_card_right">
          <RootDivReport
            className={classesxReport.selct_dropdown}
            sx={{
              minWidth: "90px!important",
              marginRight: "20px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "auto",
              },
            }}
          >
            <FormControlRootReport className={classesxReport.FormControlRoot}>
              <Select
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={KeyboardArrowDownIcon}
                labelId="phases-select-outlined-label"
                id="phases-select-outlined"
                displayEmpty
                variant="outlined"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                defaultValue={status}
                onChange={handleAccessChange}
                disabled={disable}
              >
                <MenuItemRootReport
                  className={classesxReport.MenuItemRoot}
                  value={"Accepted"}
                >
                  <AllowIcon />
                </MenuItemRootReport>
                <MenuItemRootReport
                  className={classesxReport.MenuItemRoot}
                  value={"Blocked"}
                >
                  <BlockIcon />
                </MenuItemRootReport>
              </Select>
            </FormControlRootReport>
          </RootDivReport>
          <IconButtonRoot
            sx={{ marginRight: "10px" }}
            onClick={handleEditButton}
            className={SettingClasses.IconButtonRoot}
            size="large"
          >
            <ModeEditOutlineOutlinedIcon />
          </IconButtonRoot>
          <IconButtonRoot
            sx={{
              marginRight: "-8px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px",
              },
            }}
            className={SettingClasses.IconButtonRoot}
            disabled={disable}
            onClick={handleDelete}
            size="large"
          >
            <DeleteOutlineIcon />
          </IconButtonRoot>
        </div>
      </LIRoot>
    </>
  );
}

function renderCards(
  cards,
  handleAliasChange,
  handleRFIDChange,
  handleAccessChange,
  deleteLocalCard,
  disableAllInputs,
  translation
) {
  return (
    <React.Fragment>
      {cards && cards.length > 0 ? (
        cards.map((card, index) => (
          <RFIDCard
            key={card.unqid}
            index={index}
            alias={card.alias}
            rfid={card.idtag}
            status={card.status}
            disableInputs={disableAllInputs}
            handleAliasChange={(e) => {
              handleAliasChange(e.target.value, card);
            }}
            handleRFIDChange={(e) => {
              handleRFIDChange(e.target.value, card);
            }}
            handleAccessChange={(e) => {
              handleAccessChange(e.target.value, card);
            }}
            handleDelete={() => {
              deleteLocalCard(card.unqid);
            }}
          />
        ))
      ) : (
        <RootP
          sx={{ fontWeight: 900 }}
          className={`${SettingClasses.setting_card_subheader}`}
        >
          {translation("text.no_cards_added_yet")}
        </RootP>
      )}
    </React.Fragment>
  );
}

function AuthorizedCardsSettings({ chargingStation }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const cards = useSelector((state) => state.chargingStation.cards);

  const [disableAllInputs, setDisableAllInputs] = useState(false);

  const deleteCard = (unqid) => {
    dispatch(deleteRFIDCard(unqid));
  };

  const handleAccessChange = (newStatus, card) => {
    card.status = newStatus;
  };

  const handleAliasChange = (newAlias, card) => {
    card.alias = newAlias;
  };

  const handleRFIDChange = (newIDTag, card) => {
    card.idtag = newIDTag;
  };

  useEffect(() => {
    if (chargingStation != "") {
      dispatch(getCards(chargingStation));
    }
  }, [chargingStation]);

  return (
    <>
      <SettingdDiv
        className={`${SettingClasses.CardBody} ${SettingClasses.cards_row_second_item1}`}
      >
        <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
          <SettingdDiv
            className={`${SettingClasses.title_wrapper_authorized_cards}`}
          >
            <RootH3 className={`${SettingClasses.setting_card_h3}`}>
              {t("text.authorized_cards")}
            </RootH3>
            <RootP className={`${SettingClasses.setting_card_subheader}`}>
              {t("text.authorized_cards_subheader")}
            </RootP>
          </SettingdDiv>
          <ULRoot className={`${SettingClasses.rifd_cards_block}`}>
            {renderCards(
              cards,
              handleAliasChange,
              handleRFIDChange,
              handleAccessChange,
              deleteCard,
              disableAllInputs,
              t
            )}
          </ULRoot>
          <SettingdDiv
            className={`${SettingClasses.add_card_block}`}
            sx={{
              justifyContent: "flex-start!important",
              marginTop: "18px",
              [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between!important",
              },
            }}
          >
            <RootP
              className={`${SettingClasses.Add_timer_text}`}
              sx={{ marginRight: "10px" }}
            >
              {t("text.add_rifd_card")}
            </RootP>
            <IconButtonRoot
              className={SettingClasses.IconButtonRoot}
              onClick={() => {
                dispatch(
                  appendCard({
                    idtag: "",
                    alias: "",
                    status: "Accepted",
                  })
                );
              }}
              size="large"
            >
              <AddCircleOutlineRoundedIcon sx={{ color: "#000" }} />
            </IconButtonRoot>
          </SettingdDiv>
        </SettingdDiv>

        <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
          <ButtonRoot
            className={`${SettingClasses.ButtonRoot}`}
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(saveCards(chargingStation, cards));
              setDisableAllInputs(true);
            }}
          >
            {t("button.save")}
          </ButtonRoot>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
}

function Timer({
  startChargingAt,
  stopChargingAt,
  handleStartTimerChange,
  handleStopTimerChange,
  handleDelete,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <SettingdDiv className={SettingClasses.timer_block}>
        <SettingdDiv className={SettingClasses.timer_block_Left}>
          <TextFieldRoot
            id="time"
            label={t("text.charging_begin")}
            className={SettingClasses.TextFieldRoot}
            type="time"
            defaultValue={startChargingAt}
            onChange={handleStartTimerChange}
            inputProps={{
              step: 60, // 1 min
            }}
          />
          <TextFieldRoot
            id="time"
            label={t("text.charging_end")}
            className={SettingClasses.TextFieldRoot}
            type="time"
            defaultValue={stopChargingAt}
            onChange={handleStopTimerChange}
            inputProps={{
              step: 60, // 1 min
            }}
          />
        </SettingdDiv>
        <SettingdDiv className={SettingClasses.timer_block_right}>
          <IconButtonRoot
            className={SettingClasses.IconButtonRoot}
            onClick={handleDelete}
            size="large"
          >
            <DeleteOutlineIcon />
          </IconButtonRoot>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
}

function renderTimer(
  timers,
  handleStartTimerChange,
  handleStopTimerChange,
  deleteTimer
) {
  return (
    <React.Fragment>
      {timers.map((timer, index) => {
        return (
          <>
            <Timer
              key={timer.unqid}
              unqid={timer.unqid}
              startChargingAt={timer.start_charging_at}
              stopChargingAt={timer.stop_charging_at}
              handleStartTimerChange={(e) => {
                handleStartTimerChange(e.target.value, timer);
              }}
              handleStopTimerChange={(e) => {
                handleStopTimerChange(e.target.value, timer);
              }}
              handleDelete={() => {
                deleteTimer(timer.unqid);
              }}
            />
          </>
        );
      })}
    </React.Fragment>
  );
}

function StationTimer({ chargingStation }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timers = useSelector((state) => state.chargingStation.timers);

  function handleStartTimerChange(newStartTimer, timer) {
    timer.start_charging_at = newStartTimer;
  }

  function handleStopTimerChange(newStopTimer, timer) {
    console.log(newStopTimer);
    timer.stop_charging_at = newStopTimer;
  }

  function deleteTimer(unqid) {
    dispatch(deleteChargingTimer(unqid));
  }

  useEffect(() => {
    if (chargingStation != "") {
      dispatch(getChargingTimers(chargingStation));
    }
  }, [chargingStation]);

  return (
    <>
      <SettingdDiv
        className={`${SettingClasses.CardBody} ${SettingClasses.cards_row_First_item1}`}
      >
        <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
          <RootH3 className={`${SettingClasses.setting_card_h3}`}>
            {t("text.charging_timer")}
          </RootH3>
          <RootP className={`${SettingClasses.setting_card_subheader}`}>
            {t("text.charging_timer_subheader")}
          </RootP>
          <SettingdDiv className={`${SettingClasses.timer_block_parent}`}>
            {renderTimer(
              timers,
              handleStartTimerChange,
              handleStopTimerChange,
              deleteTimer
            )}
          </SettingdDiv>
          <SettingdDiv className={`${SettingClasses.Add_timer}`}>
            <RootP className={`${SettingClasses.Add_timer_text}`}>
              {t("text.add_timer")}
            </RootP>
            <IconButtonRoot
              title={t("text.add_timer")}
              className={SettingClasses.IconButtonRoot}
              onClick={() => {
                dispatch(
                  appendTimer({
                    charging_station_id: chargingStation,
                    start_charging_at: "00:00",
                    stop_charging_at: "00:00",
                  })
                );
              }}
              size="large"
            >
              <AddCircleOutlineRoundedIcon sx={{ color: "#000" }} />
            </IconButtonRoot>
          </SettingdDiv>
        </SettingdDiv>
        <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
          <ButtonRoot
            className={`${SettingClasses.ButtonRoot}`}
            color="primary"
            variant="contained"
            disabled={timers.length === 0}
            onClick={() => {
              dispatch(saveChargingTimers(chargingStation, timers));
            }}
          >
            {t("button.save")}
          </ButtonRoot>
        </SettingdDiv>
      </SettingdDiv>
    </>
  );
}

function Settings() {
  const theme = useTheme();
  const currentUser = getFirebase().auth().currentUser;
  const csCtx = useContext(SelectedStationContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chargingStationIDs = useSelector(
    (state) => state.chargingStation.chargingStations
  );
  const addedChargingStationError = useSelector(
    (state) => state.chargingStation.addedChargingStationError
  );

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (addedChargingStationError != null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [addedChargingStationError]);

  useEffect(() => {
    if (
      chargingStationIDs !== null &&
      chargingStationIDs.length > 0 &&
      csCtx.selectedStation === ""
    ) {
      csCtx.setSelectedStation(chargingStationIDs[0].charging_station_id);
    }
  }, [chargingStationIDs]);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${SettingClasses.GridRoot_container_settings}`}
        >
          <GridRootsetting
            className={SettingClasses.section_1}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <RootDivReport
              className={classesxReport.form_row}
              sx={{
                marginBottom: "30px",
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "20px",
                },
              }}
            >
              <DashboardDiv
                className={DashboarClasses.Input_Wrapper}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    width: "100%!important",
                  },
                }}
              >
                <RootDivReport
                  className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      flexDirection: "column",
                      alignItems: "flex-start!important",
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "0px!important",
                    },
                  }}
                >
                  <Labelh4Report className={classesxReport.labelh4_form}>
                    {`${t("text.charging_station")}`}
                  </Labelh4Report>
                  <RootDivReport className={classesxReport.selct_dropdown}>
                    <FormControlRootReport
                      className={classesxReport.FormControlRoot}
                    >
                      <Select
                        value={csCtx.selectedStation}
                        onChange={(event) =>
                          csCtx.setSelectedStation(event.target.value)
                        }
                        label="Select ChargingStation"
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        {chargingStationIDs !== null &&
                        chargingStationIDs.length > 0
                          ? chargingStationIDs.map((cs) => (
                              <MenuItemRoot
                                className={DashboarClasses.MenuItemRoot}
                                key={cs.unqid}
                                value={cs.charging_station_id}
                              >
                                {cs.alias || cs.charging_station_id}
                              </MenuItemRoot>
                            ))
                          : null}
                      </Select>
                    </FormControlRootReport>
                  </RootDivReport>
                </RootDivReport>
              </DashboardDiv>
            </RootDivReport>
            <DashboardDiv
              sx={{ alignItems: "unset!important" }}
              className={`${DashboarClasses.cards_row} ${SettingClasses.cards_row_First}`}
            >
              <DashboardDiv
                className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
              >
                <StationTimer chargingStation={csCtx.selectedStation} />
              </DashboardDiv>
              <DashboardDiv
                className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
              >
                <CurrentSettings chargingStation={csCtx.selectedStation} />
              </DashboardDiv>
            </DashboardDiv>
          </GridRootsetting>
          <GridRootsetting
            className={SettingClasses.section_2}
            item
            xs={12}
            sx={{ width: "100%" }}
          >
            <DashboardDiv
              className={`${DashboarClasses.cards_row} ${SettingClasses.cards_row_second}`}
            >
              <DashboardDiv
                className={`${DashboarClasses.Col_12} ${DashboarClasses.MB_30px}`}
              >
                <CurrencySettings chargingStation={csCtx.selectedStation} />
              </DashboardDiv>
            </DashboardDiv>
          </GridRootsetting>
          <GridRootsetting
            className={SettingClasses.section_2}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <DashboardDiv
              className={`${DashboarClasses.cards_row} ${SettingClasses.cards_row_second}`}
            >
              <DashboardDiv className={`${DashboarClasses.Col_12}`}>
                <AuthorizedCardsSettings
                  chargingStation={csCtx.selectedStation}
                />
              </DashboardDiv>
            </DashboardDiv>
          </GridRootsetting>
        </GridRootDashboard>
        <Toaster />
      </RootDivReport>
    </>
  );
}

export default Settings;
