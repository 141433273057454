export const updatePasswordText = (password) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: "UPDATE_PASSWORD_TEXT", payload: password });
  };
};

export const disableSave = (disable) => {
  return (dispatch, getState, getFirebase) => {
    if (disable) {
      dispatch({ type: "DISABLE_SAVE" });
    } else {
      dispatch({ type: "ENABLE_SAVE" });
    }
  };
};
