import {
  TextField,
  Button,
  Paper,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useTheme,
} from "@mui/material";
import logo from "../../original_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendPasswordResetEmail } from "../../store/actions/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const justifyCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function AlertDialog({ dialogOpen }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    history.push("/signin");
  };

  useEffect(() => {
    setOpen(dialogOpen);
  }, [dialogOpen]);

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {t("text.password_reset_email_conf")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SendPasswordResetEmail() {
  const theme = useTheme();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.auth.authError);
  const { t } = useTranslation();

  function handleEmailFieldChange(e) {
    setEmail(e.target.value);
  }

  useEffect(() => {
    if (auth.uid) {
      history.replace("/chargingstation");
    }
    if (authError) {
      console.log(authError);
    }
  }, [auth.uid, authError, history]);

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "650px",
          minWidth: "200px",
          margin: "10px auto",
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Paper
          sx={{
            margin: "10px",
            padding: "20px",
          }}
          elevation={10}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={justifyCenter}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "230px",
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <Typography
                variant="h6"
                sx={justifyCenter}
                style={{ marginBottom: "10px", color: "#3b4240" }}
              >
                {t("text.send_password_reset_email")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("text.email")}
                placeholder="youremail@example.com"
                type="email"
                error={email !== "" && !validateEmail(email)}
                helperText={
                  email !== "" && !validateEmail(email)
                    ? "Please enter a valid email"
                    : null
                }
                onChange={handleEmailFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={email === "" || !validateEmail(email)}
                disableElevation={true}
                sx={{
                  textTransform: "none",
                  height: "40px",
                  width: "100%",
                }}
                onClick={() => {
                  dispatch(sendPasswordResetEmail(email));
                  setDialogOpen(true);
                }}
              >
                {t("button.send_email")}
              </Button>
            </Grid>
          </Grid>
          <AlertDialog dialogOpen={dialogOpen} />
        </Paper>
      </Box>
    </div>
  );
}

export default SendPasswordResetEmail;
