const initState = {
  passwordToBeUpdated: null,
  saveDisabled: true,
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PASSWORD_TEXT":
      return {
        ...state,
        passwordToBeUpdated: action.payload,
      };
    case "DISABLE_SAVE":
      return {
        ...state,
        saveDisabled: true,
      };
    case "ENABLE_SAVE":
      return {
        ...state,
        saveDisabled: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default appReducer;
