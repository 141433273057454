const initState = {
  meterPowerfox: null,
  pvChargerEnabled: false,
};

const pvReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_METER_POWERFOX":
      return {
        ...state,
        meterPowerfox: action.payload,
        pvChargerEnabled: action.payload.enabled,
      };
    case "TOGGLE_PV_CHARGER":
      return {
        ...state,
        pvChargerEnabled: action.payload.new_state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default pvReducer;
