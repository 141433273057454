import React from "react";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";

function AppleSignInButton({ title, onClick, disabled }) {
  return (
    <Button
      sx={{
        backgroundColor: "black",
        borderColor: "transparent",
        marginBottom: "10px",
        height: "50px",
        minWidth: "300px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50px",
        alignItems: "center",
        "&:hover": {
          backgroundColor: grey[900],
          borderColor: "transparent",
        },
      }}
      disableElevation={true}
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        style={{
          marginRight: "10px",
        }}
        width="18px"
        height="18px"
        viewBox="0 0 16 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Buttons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Buttons---Sign-in-with-Apple"
            transform="translate(-1245.000000, -147.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g
              id="Sign-in-with-Apple---Logo-Only"
              transform="translate(1091.000000, 77.000000)"
            >
              <g
                id="Sign-in-with-Apple-(Logo-only)"
                transform="translate(140.000000, 60.000000)"
              >
                <path
                  d="M22.2226562,14.3846154 C23.0546875,14.3846154 24.0976562,13.8048315 24.71875,13.0317864 C25.28125,12.3312142 25.6914062,11.352829 25.6914062,10.3744437 C25.6914062,10.2415766 25.6796875,10.1087095 25.65625,10 C24.7304687,10.0362365 23.6171875,10.640178 22.9492187,11.4494596 C22.421875,12.06548 21.9414062,13.0317864 21.9414062,14.0222505 C21.9414062,14.1671964 21.9648438,14.3121424 21.9765625,14.3604577 C22.0351562,14.3725366 22.1289062,14.3846154 22.2226562,14.3846154 Z M19.2929688,29 C20.4296875,29 20.9335938,28.214876 22.3515625,28.214876 C23.7929688,28.214876 24.109375,28.9758423 25.375,28.9758423 C26.6171875,28.9758423 27.4492188,27.792117 28.234375,26.6325493 C29.1132812,25.3038779 29.4765625,23.9993643 29.5,23.9389701 C29.4179688,23.9148125 27.0390625,22.9122695 27.0390625,20.0979021 C27.0390625,17.6579784 28.9140625,16.5588048 29.0195312,16.474253 C27.7773438,14.6382708 25.890625,14.5899555 25.375,14.5899555 C23.9804688,14.5899555 22.84375,15.4596313 22.1289062,15.4596313 C21.3554688,15.4596313 20.3359375,14.6382708 19.1289062,14.6382708 C16.8320312,14.6382708 14.5,16.5950413 14.5,20.2911634 C14.5,22.5861411 15.3671875,25.013986 16.4335938,26.5842339 C17.3476562,27.9129053 18.1445312,29 19.2929688,29 Z"
                  id=""
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div style={{ fontSize: "17px", color: "white", textTransform: "none" }}>
        {title}
      </div>
    </Button>
  );
}

export default AppleSignInButton;
