import React, { useEffect } from "react";
import { Grid, IconButton, TextField, useTheme, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadTransactionsCSV,
  downloadTransactionsDPF,
  getAllChargingStationTransactions,
  getCards,
  getChargingStations,
} from "../../store/actions/chargingStation";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { getFirebase } from "react-redux-firebase";
import {
  LoadingTable,
  LoadingCard,
  SelectstackReport,
} from "./../common/loading_placeholder/Loading";
import { usePrevious } from "../../lib/hooks/customHooks";
import { TransactionsCard } from "../common/TransactionsCard/TransactionsCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Background from "../common/background/background";
import { useContext } from "react";
import { SelectedStationContext } from "../contexts/selectedStation";
export const PREFIX = "history";

export const classesx = {
  page_wrapper: `page_wrapper`,
  form_row: `${PREFIX}-form_row`,
  selectflex: `${PREFIX}-selectflex`,
  labelh4_form: `${PREFIX}-labelh4_form`,
  labelh6_form: `${PREFIX}-labelh6_form`,
  FormControlRoot: `${PREFIX}-FormControlRoot`,
  selct_dropdown: `${PREFIX}-selct_dropdown`,
  MR_20px: `MR_20px`,
  IconButtonRoot: `${PREFIX}-IconButtonRoot`,
  table_block_header: `${PREFIX}-table_block_header`,
  labelh4_table: `${PREFIX}-labelh4_table`,
  table_wrapper_block: `${PREFIX}-table_wrapper_block`,
  table_block: `${PREFIX}-table_block`,
  TableCellRootHead: `${PREFIX}-TableCellRootHead`,
  TableCellRootData: `${PREFIX}-TableCellRootData`,
  TableContainerRoot: `${PREFIX}-TableContainerRoot`,
  TablePaginationRoot: `${PREFIX}-TablePaginationRoot`,
  table_cover: `${PREFIX}-table_cover`,
  selectflex_wrapper_1: `${PREFIX}-selectflex_wrapper_1`,
  Card_wrapper_block: `${PREFIX}-Card_wrapper_block`,
  Card_wrapper_block_grid: `${PREFIX}-Card_wrapper_block_grid`,
  MenuItemRoot: `${PREFIX}-MenuItemRoot`,
  DatePickerRoot: `${PREFIX}-DatePickerRoot`,
};
export const RootDiv = styled("div")(({ theme }) => ({
  [`&.${classesx.page_wrapper}`]: {
    width: "100%",
    padding: "60px 30px 0 363px",
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 70px)",
    [theme.breakpoints.down("xl")]: {
      padding: "50px 15px 0 303px",
      minHeight: "calc(100vh - 65px)",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "45px 15px 0 295px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "35px 20px 0 20px",
      minHeight: "calc(100vh - 60px)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "35px 15px 0 15px",
      minHeight: "calc(100vh - 100px)",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 15px 0 15px",
      minHeight: "calc(100vh - 80px)",
    },
  },
  [`&.${classesx.form_row}`]: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
  },
  [`&.${classesx.selectflex_wrapper_1}`]: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "25px",
    [theme.breakpoints.down("lg")]: {
      alignItems: "end",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
  [`&.${classesx.selectflex}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`&.${classesx.MR_20px}`]: {
    marginRight: "20px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  [`&.${classesx.selct_dropdown}`]: {
    minWidth: "265px",
    [theme.breakpoints.down("lg")]: {
      minWidth: "100%",
    },
  },
  [`&.${classesx.table_block_header}`]: {
    margin: "30px 0",
    [theme.breakpoints.down("xl")]: {
      margin: "30px 0 20px 0",
    },
  },
  [`&.${classesx.table_wrapper_block}`]: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  [`&.${classesx.table_cover}`]: {
    cursor: "pointer",
    paddingBottom: "20px",
    background: "rgb(43 141 117 / 7%)",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "20px",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      paddingBottom: "15px",
      borderRadius: "16px",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: "12px",
      borderRadius: "10px",
    },
  },
  [`&.${classesx.Card_wrapper_block}`]: {
    width: "100%",
    display: "none",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  [`&.${classesx.Card_wrapper_block_grid}`]: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    flexDirection: "column",
  },
}));

export const TableContainerRoot = styled(TableContainer)(({ theme }) => ({
  [`&.${classesx.TableContainerRoot}`]: {
    overflow: "auto",
    background: "#FFFFFF",
    minHeight: "670px",
    [theme.breakpoints.down("xl")]: {
      minHeight: "580px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "550px",
    },
    [`&::-webkit-scrollbar`]: {
      width: "10px",
      height: "10px",
      [theme.breakpoints.down("xl")]: {
        width: "8px",
        height: "8px",
      },
    },
    [`&::-webkit-scrollbar-track`]: {
      background: theme.palette.secondary.light,
    },
    [`&::-webkit-scrollbar-thumb `]: {
      background: theme.palette.primary.main,
    },
    [`&::-webkit-scrollbar-thumb:hover`]: {
      background: theme.palette.primary.light,
    },
    [`& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)`]: {
      backgroundColor: "rgb(43 141 117 / 7%)",
    },
    [`& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:hover`]: {
      backgroundColor: theme.palette.secondary.light,
      [`& .history-TableCellRootData`]: {
        fontWeight: 500,
        transition: "all 0.4s cubic-bezier(0.19, 1, 0.22, 1)",
      },
    },
  },
}));

export const Labelh4 = styled("h4")(({ theme }) => ({
  [`&.${classesx.labelh4_form}`]: {
    marginRight: "17px",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.2,
    color: "#1C1C1C",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
      marginRight: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "15px",
      paddingLeft: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  [`&.${classesx.labelh4_table}`]: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.2,
    color: "#B3B3B3",
    textTransform: "uppercase",
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}));

export const Labelh6 = styled("h6")(({ theme }) => ({
  [`&.${classesx.labelh6_form}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: 1,
    color: "#777777",
    marginRight: "10px",
    [theme.breakpoints.down("xl")]: {
      marginRight: "15px",
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginRight: "unset",
      marginBottom: "15px",
      paddingLeft: "8px",
    },
  },
}));

export const FormControlRoot = styled(FormControl)(({ theme }) => ({
  [`&.${classesx.FormControlRoot}`]: {
    margin: 0,
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "35px",
    [`& .MuiSelect-select.MuiSelect-outlined`]: {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1,
      padding: "22px 40px 22px 25px",
      fontFamily: "'Roboto', sans-serif",
      textTransform: "capitalize",
      [theme.breakpoints.down("xl")]: {
        padding: "16px 20px 16px 20px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "14px 20px 14px 20px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px 12px 12px 14px",
        fontSize: "14px",
      },
    },
    [`& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline`]: {
      border: "unset!important",
    },
    [`& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium `]: {
      right: "12px",
      color: theme.palette.primary.main,
      transition: "all 0.4s ease",
      [theme.breakpoints.down("xl")]: {
        fontSize: "1.4em",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.15em",
        right: "6px",
      },
    },
  },
}));

export const IconButtonRoot = styled(IconButton)(({ theme }) => ({
  [`&.${classesx.IconButtonRoot}`]: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "35px",
    width: "70px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xl")]: {
      width: "55px",
      height: "55px",
      padding: "8px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "51px",
      height: "51px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "44px",
      height: "44px",
      padding: "4px",
    },
    [`& .MuiSvgIcon-root`]: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xl")]: {
        fontSize: "1.35rem",
      },
    },
    [`&:hover`]: {
      backgroundColor: theme.palette.primary.main,
      [`& .MuiSvgIcon-root`]: {
        color: "#fff",
      },
    },
  },
  [`&.${classesx.MR_20px}`]: {
    marginRight: "20px",
    [theme.breakpoints.down("xl")]: {
      marginRight: "15px",
    },
  },
}));

export const TablePaginationRoot = styled(TablePagination)(({ theme }) => ({
  [`&.${classesx.TablePaginationRoot}`]: {
    display: "flex",
    justifyContent: "start",
    position: "absolute",
    left: 0,
    bottom: "-60px",
    [theme.breakpoints.down("sm")]: {
      bottom: "-30px",
    },
    [`& .MuiToolbar-root`]: {
      padding: 0,
      minHeight: "40px",
      [`& .MuiTablePagination-displayedRows`]: {
        fontSize: "16px",
        lineHeight: 1,
        textTransform: "capitalize",
        letterSpacing: "unset",
        color: "#1C1C1C",
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
      },
      [`&  .MuiButtonBase-root`]: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down("lg")]: {
          padding: "4px",
        },
        [`& .MuiSvgIcon-root`]: {
          [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: "18px",
          },
        },
        [`&:hover`]: {
          color: "#fff",
          backgroundColor: theme.palette.primary.light,
        },
      },
      [`& .MuiTablePagination-actions`]: {
        marginLeft: "10px",
      },
    },
    [`& .MuiTablePagination-selectLabel , & .MuiInputBase-root , & .MuiTablePagination-spacer`]: {
      display: "none",
    },
  },
}));

export const TableCellRoot = styled(TableCell)(({ theme }) => ({
  [`&.${classesx.TableCellRootHead}`]: {
    background: theme.palette.primary.main,
    color: "#fff",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    border: "unset",
    letterSpacing: "normal",
    padding: "30px 16px",
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      padding: "26px 14px",
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "24px 12px",
    },
  },
  [`&.${classesx.TableCellRootData}`]: {
    border: "unset",
    padding: "30px 16px",
    color: "#777777",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("xl")]: {
      padding: "26px 14px",
      fontSize: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "24px 12px",
    },
  },
}));

export const MenuItemRoot = styled(MenuItem)(({ theme }) => ({
  [`&.${classesx.MenuItemRoot}`]: {
    lineHeight: "normal",
    fontWeight: 500,
    padding: "10px 5px 10px 10px",
    minHeight: "unset",
    [theme.breakpoints.down("xl")]: {
      padding: "8px 5px 8px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px 8px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

export const DatePickerRoot = styled(DatePicker)(({ theme }) => ({
  [`&.${classesx.DatePickerRoot}`]: {
    maxWidth: "245px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      width: "100%",
    },
    [`& .MuiInputBase-input`]: {
      padding: "22.5px 10px 22.5px 20px",
      fontWeight: 500,
      fontSize: "18px",
      color: "#000",
      [theme.breakpoints.down("xl")]: {
        padding: "16px 10px 16px 20px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "14px 10px 14px 20px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px 10px 12px 20px",
        fontSize: "14px",
      },
    },
    [`& .MuiInputBase-formControl`]: {
      paddingRight: "20px",
      borderRadius: "65px",
      backgroundColor: "#fff",
      boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "14px",
      },
    },
    [`& .MuiOutlinedInput-notchedOutline`]: {
      borderColor: "#64646491",
    },
    [`& .MuiButtonBase-root`]: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
        [`& svg`]: {
          fontSize: "1.4rem",
        },
      },
    },
    [`& .MuiFormLabel-root`]: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    [`&  .MuiFormLabel-root`]: {
      top: "8px",
      left: "10px",
      [theme.breakpoints.down("xl")]: {
        top: "0px",
      },
      [theme.breakpoints.down("lg")]: {
        top: "-2px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        top: "-3px",
      },
    },
    [`& .MuiFormLabel-root.Mui-disabled`]: {
      top: "8px",
      left: "10px",
      [theme.breakpoints.down("xl")]: {
        top: "0px",
      },
      [theme.breakpoints.down("lg")]: {
        top: "-2px",
      },
      [theme.breakpoints.down("xs")]: {
        top: "-3px",
      },
    },
    [`&  .MuiFormLabel-root.Mui-focused`]: {
      top: "0px",
      left: 0,
    },
    [`& .MuiFormLabel-root.Mui-disabled.MuiFormLabel-filled`]: {
      top: "0px",
      left: 0,
    },
    [`& .MuiFormLabel-root.MuiFormLabel-filled`]: {
      top: "0px",
      left: 0,
    },
  },
}));

function Report() {
  const currentUser = getFirebase().auth().currentUser;
  const d = new Date();
  const [valueFrom, setValueFrom] = React.useState(
    new Date(d.getFullYear(), d.getMonth() - 1)
  );
  const [valueTo, setValueTo] = React.useState(new Date());

  const [fromMonQueryParam, setFromMonQueryParam] = React.useState(
    valueFrom.toLocaleString("en", { month: "short" })
  );
  const [fromYearQueryParam, setFromYearQueryParam] = React.useState(
    valueFrom.getFullYear()
  );
  const [toMonQueryParam, setToMonQueryParam] = React.useState(
    valueTo.toLocaleString("en", { month: "short" })
  );
  const [toYearQueryParam, setToYearQueryParam] = React.useState(
    valueTo.getFullYear()
  );
  const [tx, setTx] = React.useState([]);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const csCtx = useContext(SelectedStationContext);
  const [selectedCard, setSelectedCard] = React.useState("");
  const prevCard = usePrevious(selectedCard);
  const prevStation = usePrevious(csCtx.selectedStation);
  const [totalPowerUsed, setTotalPowerUsed] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState(0);
  //For pagination ---
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [page, setPage] = React.useState(0);

  const chargingStationIDs = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const transactions = useSelector(
    (state) => state.chargingStation.transactions
  );

  const cards = useSelector((state) => state.chargingStation.cards);

  const getChargingStationTransactionsRequestLoading = useSelector(
    (state) =>
      state.chargingStationRequestState
        .getChargingStationTransactionsRequestLoading
  );

  function getDate(time) {
    var d = new Date(Date.parse(time));
    return moment(d)
      .locale("de")
      .tz("Europe/Berlin")
      .format("HH:mm:ss, DD.MM.YY");
  }

  function handleDownloadCSV() {
    if (csCtx.setSelectedStation !== "") {
      dispatch(
        downloadTransactionsCSV(
          csCtx.selectedStation,
          selectedCard,
          fromMonQueryParam,
          fromYearQueryParam,
          toMonQueryParam,
          toYearQueryParam
        )
      );
    }
  }

  function handleDownloadPDF() {
    if (csCtx.setSelectedStation !== "") {
      dispatch(
        downloadTransactionsDPF(
          csCtx.selectedStation,
          selectedCard,
          fromMonQueryParam,
          fromYearQueryParam,
          toMonQueryParam,
          toYearQueryParam
        )
      );
    }
  }

  function handleRefresh() {
    if (csCtx.selectedStation !== "") {
      dispatch(
        getAllChargingStationTransactions(
          csCtx.selectedStation,
          selectedCard,
          fromMonQueryParam,
          fromYearQueryParam,
          toMonQueryParam,
          toYearQueryParam
        )
      );
    }
  }

  const columns = [
    {
      field: "meterStart",
      headerName: t("text.meter_start"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "meterStop",
      headerName: t("text.meter_stop"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "powerUsed",
      headerName: t("text.power_used"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "cost",
      headerName: t("text.cost") + " €",
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "txStartAt",
      headerName: t("text.transaction_start_at"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "txStopAt",
      headerName: t("text.transaction_stop_at"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "reason",
      headerName: t("text.transaction_start_reason"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "idTag",
      headerName: t("text.id_tag"),
      maxwidth: [180, 160],
      align: "left",
    },
    {
      field: "txID",
      headerName: t("text.transaction_id"),
      maxwidth: [180, 160],
      align: "left",
    },
  ];

  useEffect(() => {
    if (csCtx.selectedStation !== "") {
      dispatch(
        getAllChargingStationTransactions(
          csCtx.selectedStation,
          selectedCard,
          fromMonQueryParam,
          fromYearQueryParam,
          toMonQueryParam,
          toYearQueryParam
        )
      );
    }
  }, [
    csCtx.selectedStation,
    fromMonQueryParam,
    fromYearQueryParam,
    toMonQueryParam,
    toYearQueryParam,
  ]);

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      chargingStationIDs !== null &&
      chargingStationIDs.length > 0 &&
      csCtx.selectedStation === ""
    ) {
      csCtx.setSelectedStation(chargingStationIDs[0].charging_station_id);
    }
  }, [chargingStationIDs]);

  useEffect(() => {
    if (csCtx.selectedStation !== "") {
      dispatch(getCards(csCtx.selectedStation));
    }
  }, [csCtx.selectedStation]);

  useEffect(() => {
    if (csCtx.selectedStation !== "" && selectedCard !== prevCard) {
      dispatch(
        getAllChargingStationTransactions(
          csCtx.selectedStation,
          selectedCard,
          fromMonQueryParam,
          fromYearQueryParam,
          toMonQueryParam,
          toYearQueryParam
        )
      );
    } else if (
      csCtx.selectedStation !== "" &&
      csCtx.selectedStation !== prevStation
    ) {
      dispatch(getCards(csCtx.selectedStation));
    }
  }, [
    csCtx.selectedStation,
    selectedCard,
    fromMonQueryParam,
    fromYearQueryParam,
    toMonQueryParam,
    toYearQueryParam,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if the state of cards changes, set the selectedCard to ""
    setSelectedCard("");
  }, [cards]);

  useEffect(() => {
    setFromMonQueryParam(valueFrom.toLocaleString("en", { month: "short" }));
    setFromYearQueryParam(valueFrom.getFullYear());
    setToMonQueryParam(valueTo.toLocaleString("en", { month: "short" }));
    setToYearQueryParam(valueTo.getFullYear());
  }, [valueFrom, valueTo]);

  function createTx(transaction) {
    const meterStart = Number(
      transaction.meter_start / 1000
    ).toLocaleString(i18n.language, { minimumFractionDigits: 2 });
    const meterStop = Number(
      transaction.meter_stop / 1000
    ).toLocaleString(i18n.language, { minimumFractionDigits: 2 });
    const powerUsed = Number(
      (transaction.meter_stop - transaction.meter_start) / 1000
    ).toLocaleString(i18n.language, { minimumFractionDigits: 2 });
    const cost = Number(transaction.cost).toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
    });
    const txStartAt = getDate(transaction.transaction_start_at);
    const txStopAt = getDate(transaction.transaction_stop_at);
    const reason = transaction.transaction_start_reason;
    const txState = transaction.transaction_state;
    const txID = transaction.transaction_id;
    var idTag = transaction.idtag;

    if (transaction.alias !== "") {
      idTag = `${transaction.idtag} / ${transaction.alias}`;
    }

    return {
      meterStart,
      meterStop,
      powerUsed,
      cost,
      txStartAt,
      txStopAt,
      txID,
      idTag,
      reason,
      txState,
    };
  }

  useEffect(() => {
    if (transactions !== null) {
      setTx([]);
      transactions.map((t) => {
        if (t.meter_stop !== 0) {
          setTx((txArray) => [...txArray, createTx(t)]);
        }
      });
    }
  }, [transactions]);

  useEffect(() => {
    if (transactions !== null) {
      var totalCost = transactions.reduce(
        (accumulator, t) => (accumulator = accumulator + Number(t.cost)),
        0
      );
      var totalPowerUsed = transactions.reduce(
        (accumulator, t) =>
          (accumulator =
            accumulator + (Number(t.meter_stop) - Number(t.meter_start))),
        0
      );
      setTotalCost(totalCost);
      setTotalPowerUsed(totalPowerUsed);
    }
  }, [transactions]);

  //For pagination --
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Background />
      <RootDiv className={classesx.page_wrapper}>
        <Grid
          container
          item
          xs={12}
          sx={{
            height: "100%",
            marginBottom: "100px",
            position: "relative",
            [theme.breakpoints.down("md")]: {
              marginBottom: "80px",
            },
            [theme.breakpoints.down("sm")]: {
              marginBottom: "50px",
            },
            [theme.breakpoints.down("xs")]: {
              marginBottom: "40px",
            },
          }}
        >
          <Grid item xs={12}>
            <RootDiv className={classesx.form_row}>
              <RootDiv className={classesx.selectflex_wrapper_1}>
                {chargingStationIDs !== null &&
                chargingStationIDs.length > 0 ? (
                  <>
                    <RootDiv
                      className={`${classesx.selectflex} ${classesx.MR_20px} `}
                      sx={{
                        flexWrap: "wrap",
                        [theme.breakpoints.down("lg")]: {
                          width: "50%",
                        },
                        [theme.breakpoints.down("md")]: {
                          width: "60%",
                        },
                        [theme.breakpoints.down("sm")]: {
                          width: "calc(100% - 66px)",
                        },
                        [theme.breakpoints.down("xs")]: {
                          width: "calc(100% - 60px)",
                        },
                      }}
                    >
                      <Labelh4 className={classesx.labelh4_form}>
                        {t("text.charging_station")}
                      </Labelh4>
                      <RootDiv className={classesx.selct_dropdown}>
                        <FormControlRoot className={classesx.FormControlRoot}>
                          <Select
                            value={csCtx.selectedStation}
                            onChange={(event) =>
                              csCtx.setSelectedStation(event.target.value)
                            }
                            label="Select ChargingStation"
                            inputProps={{ "aria-label": "Without label" }}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            {chargingStationIDs !== null &&
                            chargingStationIDs.length > 0
                              ? chargingStationIDs.map((cs) => (
                                  <MenuItemRoot
                                    className={classesx.MenuItemRoot}
                                    key={cs.unqid}
                                    value={cs.charging_station_id}
                                  >
                                    {cs.alias || cs.charging_station_id}
                                  </MenuItemRoot>
                                ))
                              : null}
                          </Select>
                        </FormControlRoot>
                      </RootDiv>
                    </RootDiv>
                    <Tooltip placement="left" title="Refresh">
                      <IconButtonRoot
                        className={`${classesx.IconButtonRoot}`}
                        onClick={handleRefresh}
                        aria-label="refresh"
                        size="large"
                      >
                        <RefreshOutlinedIcon />
                      </IconButtonRoot>
                    </Tooltip>
                  </>
                ) : (
                  <SelectstackReport />
                )}
              </RootDiv>
              <RootDiv
                className={classesx.selectflex_wrapper_1}
                sx={{ marginBottom: "unset!important" }}
              >
                {chargingStationIDs !== null &&
                chargingStationIDs.length > 0 ? (
                  <>
                    <RootDiv
                      className={`${classesx.selectflex} ${classesx.MR_20px}`}
                      sx={{
                        flexWrap: "wrap",
                        [theme.breakpoints.down("lg")]: {
                          width: "50%",
                        },
                        [theme.breakpoints.down("md")]: {
                          width: "60%",
                        },
                        [theme.breakpoints.down("sm")]: {
                          width: "100%",
                        },
                      }}
                    >
                      <Labelh6 className={classesx.labelh6_form}>
                        Filter
                      </Labelh6>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePickerRoot
                          className={classesx.DatePickerRoot}
                          sx={{
                            margin: "0px 15px 0 0",
                            [theme.breakpoints.down("lg")]: {
                              margin: "0px 0px 20px 0",
                            },
                          }}
                          disableFuture
                          label={t("text.from_filter")}
                          openTo="month"
                          views={["year", "month"]}
                          value={valueFrom}
                          onChange={(newValue) => {
                            setValueFrom(new Date(newValue));
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePickerRoot
                          className={classesx.DatePickerRoot}
                          sx={{
                            margin: "0px 15px 0 0",
                            [theme.breakpoints.down("lg")]: {
                              margin: "0px 0px 20px 0",
                            },
                          }}
                          disableFuture
                          label={t("text.to_filter")}
                          openTo="month"
                          views={["year", "month"]}
                          value={valueTo}
                          onChange={(newValue) => {
                            setValueTo(new Date(newValue));
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <RootDiv
                        sx={{
                          minWidth: "245px!important",
                          [theme.breakpoints.down("lg")]: {
                            width: "100%",
                          },
                        }}
                        className={classesx.selct_dropdown}
                      >
                        <FormControlRoot className={classesx.FormControlRoot}>
                          <Select
                            value={selectedCard}
                            defaultValue=""
                            displayEmpty
                            onChange={(event) =>
                              setSelectedCard(event.target.value)
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            <MenuItemRoot
                              className={classesx.MenuItemRoot}
                              value=""
                            >
                              {t("text.all_idtags")}
                            </MenuItemRoot>
                            {cards !== null && cards.length > 0
                              ? cards.map((card) => (
                                  <MenuItemRoot
                                    className={classesx.MenuItemRoot}
                                    key={card.unqid}
                                    value={card.idtag}
                                  >
                                    {card.alias || card.idtag}
                                  </MenuItemRoot>
                                ))
                              : null}
                          </Select>
                        </FormControlRoot>
                      </RootDiv>
                    </RootDiv>
                  </>
                ) : (
                  <SelectstackReport />
                )}
              </RootDiv>
            </RootDiv>
          </Grid>
          <Grid item xs={12}>
            <Labelh6
              className={classesx.labelh6_form}
              sx={{ marginTop: "30px" }}
            >
              {t("text.power_consumed_cost")}{" "}
              {Number(totalPowerUsed / 1000).toLocaleString(i18n.language, {
                minimumFractionDigits: 2,
              }) +
                " kWh, " +
                Number(totalCost).toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                }) +
                " €"}
            </Labelh6>
          </Grid>
          <Grid
            className={classesx.table_block}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <RootDiv className={classesx.table_block_header}>
              <Labelh4 className={classesx.labelh4_table}>
                {t("text.charging_history_title")}
              </Labelh4>
            </RootDiv>
            <RootDiv className={classesx.table_wrapper_block}>
              {getChargingStationTransactionsRequestLoading !== null &&
              getChargingStationTransactionsRequestLoading === true ? (
                <>
                  <LoadingTable />
                </>
              ) : (
                <>
                  {transactions !== null ? (
                    <>
                      <RootDiv className={classesx.table_cover}>
                        <TableContainerRoot
                          className={classesx.TableContainerRoot}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCellRoot
                                    key={column.field}
                                    align={column.align}
                                    className={classesx.TableCellRootHead}
                                    sx={{
                                      minWidth: column.maxwidth[0],
                                      [theme.breakpoints.down("lg")]: {
                                        minWidth: column.maxwidth[1],
                                      },
                                    }}
                                  >
                                    {column.headerName}
                                  </TableCellRoot>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tx
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.uid}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.field];
                                        return (
                                          <TableCellRoot
                                            className={
                                              classesx.TableCellRootData
                                            }
                                            key={column.field}
                                            align={column.align}
                                          >
                                            {value}
                                          </TableCellRoot>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainerRoot>
                      </RootDiv>
                      <TablePaginationRoot
                        className={classesx.TablePaginationRoot}
                        component="div"
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : null}
                </>
              )}
            </RootDiv>
            <RootDiv className={classesx.Card_wrapper_block}>
              {getChargingStationTransactionsRequestLoading !== null &&
              getChargingStationTransactionsRequestLoading === true ? (
                <>
                  <LoadingCard />
                </>
              ) : (
                <>
                  {transactions !== null ? (
                    <>
                      <RootDiv className={classesx.Card_wrapper_block_grid}>
                        {tx
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data) => (
                            <TransactionsCard data={data} key={data.uid} />
                          ))}
                      </RootDiv>
                      <TablePaginationRoot
                        className={classesx.TablePaginationRoot}
                        component="div"
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : null}
                </>
              )}
            </RootDiv>
          </Grid>
        </Grid>
      </RootDiv>
      <Tooltip placement="top" title="Download PDF">
        <IconButtonRoot
          aria-label="Download_pdf"
          className={`${classesx.IconButtonRoot}`}
          sx={{
            position: "fixed",
            zIndex: 5,
            bottom: "16px",
            right: "16px",
            [theme.breakpoints.down("xl")]: {
              right: "12px",
            },
          }}
          onClick={handleDownloadPDF}
          size="large"
        >
          <PictureAsPdfOutlinedIcon />
        </IconButtonRoot>
      </Tooltip>
      <Tooltip placement="top" title="Download CSV">
        <IconButtonRoot
          className={`${classesx.IconButtonRoot}`}
          sx={{
            position: "fixed",
            zIndex: 5,
            bottom: "16px",
            right: "100px",
            [theme.breakpoints.down("xl")]: {
              right: "77px",
            },
            [theme.breakpoints.down("lg")]: {
              right: "72px",
            },
            [theme.breakpoints.down("xs")]: {
              right: "65px",
            },
          }}
          onClick={handleDownloadCSV}
          aria-label="download"
          size="large"
        >
          <CloudDownloadOutlinedIcon />
        </IconButtonRoot>
      </Tooltip>
    </>
  );
}
export default Report;
