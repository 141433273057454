import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

function Footer() {
  const d = new Date();
  const location = useLocation();
  const { t } = useTranslation();
  return location.pathname === "/" ||
    location.pathname === "/signin" ||
    location.pathname === "/signup" ? (
    <footer style={{ width: "100%", marginTop: "auto" }}>
      <Box px={{ xs: 2, sm: 5 }} py={{ xs: 2, sm: 5 }} bgcolor="white">
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            direction="column"
            sx={{ margin: "5px" }}
          >
            <Grid
              container
              item
              justifyContent="space-between"
              spacing={2}
              direction="row"
              sx={{
                margin: "5px",
              }}
            >
              <Grid item xs={12} sm={4}>
                <Box>
                  <Link
                    underline="none"
                    href="https://www.daheimladen.de/datenschutz"
                  >
                    {t("text.data_policy")}
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <Link
                    underline="none"
                    href="https://www.daheimladen.de/impressum"
                  >
                    Impressum
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <Link
                    underline="none"
                    href="https://www.daheimladen.de/release-notes"
                  >
                    Release Notes
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              spacing={2}
              direction="row"
              sx={{ margin: "5px" }}
            >
              <Grid item xs={12} sm={4}>
                <Box>
                  <Link underline="none" href="mailto:info@daheimladen.de">
                  info@injet.energy
                  </Link>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              direction="row"
              spacing={1}
              sx={{ margin: "5px" }}
            >
              <Grid item xs={12}>
                <Box color="rgb(105,105,105)">
                  © {d.getFullYear()} New Inergy GmbH
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box color="rgb(105,105,105)" style={{ fontSize: "12px" }}>
                  Version {process.env.REACT_APP_BUILD_VERSION || "dev"}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  ) : null;
}

export default Footer;
